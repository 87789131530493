import React, {useEffect, useState} from 'react'
import axios from 'axios';
import profil from '../Public/iconprofil.png';
import versprofil from '../Public/fleche.png';
import verification from '../Public/profilchecked.png';
import contact from '../Public/contact.png';
import personne from './2pers.png'
import anime from './anim.png'
import siege from './siege.png'
import prise from './prise.png'
import clim from './clim.png'
import car from '../Public/car.png'
import './detailstrajet.css'

const DetailsTrajet = () => {
    const [depart, setDepart] = useState('');
	const [arrivee, setArrivee] = useState('');
    const [date, setDate] = useState('');
	const [heure, setHeure] = useState('');
	
    const [nb_personne, setNbPersonne] = useState('');
	const [tarif, setTarif] = useState('');
	const [user_id, setUserid] = useState('');
	const [userName, setUserName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [distance, setDistance] = useState('');
    const [colis, setColis] = useState([]);
	const [taille, setTaille] = useState('');
	const [poid_max, setPoidmax] = useState('');
	
	const [resultats, setResultats] = useState([]);

	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(false);
	const [checkbox4, setCheckbox4] = useState(false);
	const [checkbox5, setCheckbox5] = useState(false);
   

	const [id, setTripId] = useState(null); 
    const [colisId, setColisId] = useState(null);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const tripId = urlParams.get('id');
	  
		console.log("tripId from URL:", tripId); 
	  
		setTripId(tripId); 
		setColisId(tripId);
	  
		
		
	  }, []);

	  useEffect(() => {
		
		console.log("id of trip", id);
	  
	  }, [id]);

	function convertMonthToString(date) {
		const monthNumber = new Date(date).getMonth() + 1;
		const monthNames = [
		  'janvier',
		  'février',
		  'mars',
		  'avril',
		  'mai',
		  'juin',
		  'juillet',
		  'août',
		  'septembre',
		  'octobre',
		  'novembre',
		  'décembre',
		];
	  
		if (monthNumber >= 1 && monthNumber <= 12) {
		  return monthNames[monthNumber - 1];
		} else {
		  return 'Mois invalide';
		}
	  }

	  function extractDay(date){
             
		const day = new Date(date).getDate();
		return day;
	  }

	  function extractDayOfWeek(date) {
		const dayOfWeekNumber = new Date(date).getDay();
		const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'samedi'];
        return dayNames[dayOfWeekNumber];
	  }
	  
	  useEffect(() => {
		const token = localStorage.getItem('token');
		if (!token) {
		  // Si le token n'existe pas, rediriger vers la page de connexion
		  window.location.href = '/login';
		  return;
		}
	
		const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
		const currentTime = new Date();
	
		if (currentTime >= tokenExpirationDate) {
		  // Si le token a expiré, rediriger vers la page de connexion
		  window.location.href = '/login';
		}
	  }, []);

    useEffect(() => {
		const fetchTripDetails = async () => {
			
		  try {
			
			const response = await axios.get(`https://test-api.bookair.space/api/trajetdetails/${id}`);
			const {depart, arrivee, date, heure, tarif, distance, user_id, nb_personne, checkbox1, checkbox2, checkbox3, checkbox4, checkbox5} = response.data;
			
			setDepart(depart);
			setArrivee(arrivee);
			setDate(date);
			setHeure(heure);
			setTarif(tarif);
            setDistance(distance);
			setUserid(user_id);
			setNbPersonne(nb_personne);
			setCheckbox1(checkbox1);
			setCheckbox2(checkbox2);
			setCheckbox3(checkbox3);
			setCheckbox4(checkbox4);
			setCheckbox5(checkbox5);

			const responseUser = await axios.get(`https://test-api.bookair.space/api/userdetails/${user_id}`);
			
			const { user } = responseUser.data;
			const { name, firstName } = user;
		
			setUserName(name);
			setUserLastName(firstName);

         
		   
		  } catch (error) {
			console.error('Erreur lors de la récupération des détails du trajet :', error);
		  }
	
		};
	    
		fetchTripDetails();
	  }, [id]);

    
	  function extractHour(time) {
		const [hours] = time.split(":");
		return hours;
	  }	  
     
	  function extractMinutes(time) {
		const [, minutes] = time.split(":");
		return minutes;
	  }
	
    function removePostalCode(address) {
      return address.replace(/\d+/, '').trim();
    }

	  function extractHour(time) {
		const [hours] = time.split(":");
		return hours;
	  }	  
     
	  function extractMinutes(time) {
		const [, minutes] = time.split(":");
		return minutes;
	  }
	
    function removePostalCode(address) {
      return address.replace(/\d+/, '').trim();
    }

    function extraireVille(adresse) {
      const adresseSplit = adresse.split(',');
      if (adresseSplit.length >= 2) {
        const ville = adresseSplit[adresseSplit.length - 2].trim();
        return ville;
      } else {
        return null;
      }
    }

	function removeCountryFromAddress(address) {
		const addressArray = address.split(','); 
		if (addressArray.length > 1) {
		  addressArray.pop();
		  return addressArray.join(',').trim(); 
		} else {
		  return '';
		}
	  }
	

	 const handleReservationClick = () => {
		
		  window.location.href = '/paiement';

	  };

	  useEffect(() => {
		const token = localStorage.getItem('token');
		if (!token) {
		  window.location.href = '/login';
		  return;
		}
	
		const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
		const currentTime = new Date();
	
		if (currentTime >= tokenExpirationDate) {
		  window.location.href = '/login';
		}
	  }, []);
	
	
	  useEffect(() => {
		const fetchColis = async () => {
		  const urlParams = new URLSearchParams(window.location.search);
		  const tripId = urlParams.get('id');
	  
		  const params = {
			id: tripId,
		  };
	  
		  try {
			const responseColis = await axios.get('https://test-api.bookair.space/api/affichageColis', { params });
			const colis = responseColis.data;
	  
			const userDetailPromises = colis.map(async (coli) => {
			  const depart = coli.depart;
			  setDepart(depart);
			  const arrivee = coli.arrivee;
			  setArrivee(arrivee);
			  const date = coli.date;
			  setDate(date);
			  const heure = coli.heure;
			  setHeure(heure);
			  const taille = coli.taille;
			  setTaille(taille);
			  const poid_max = coli.poids_max;
			  setPoidmax(poid_max);
			  const tarif = coli.tarif;
			  setTarif(tarif);
			  const user_id = coli.user_id;
			  setUserid(user_id);
	  
			  console.log('Départ:', depart);
			  console.log('Arrivée:', arrivee);
			  console.log('Date:', date);
			  console.log('heure:', heure);
			  console.log('taille:', taille);
			  console.log("poid max", poid_max);
			  console.log('tarif:', tarif);
			  console.log("user id", user_id);
	  
			  // Effectuer la requête userdetails pour chaque utilisateur
			  const responseUser = await axios.get(`https://test-api.bookair.space/api/userdetails/${user_id}`);
			  const { user } = responseUser.data;
			  const { name, firstName } = user;
			  console.log("the id of the user",user_id);
			  console.log("the name and the firstname",name, firstName);
			  setUserName(name);
			  setUserLastName(firstName);
			});
	  
			// Attendre que toutes les requêtes userdetails se terminent
			await Promise.all(userDetailPromises);
	  
			setColis(colis);
		  } catch (error) {
			console.error('Erreur lors de la récupération des colis :', error);
		  }
		};
	  
		fetchColis();
	  }, []);
	  
	
    return (

		<div>
		<div className='date2'>
					<p> { extractDayOfWeek(date)} { extractDay(date)} {convertMonthToString(date)}</p>
				</div>
				<hr className="details-horizontal-line1" />
				<div className="deparv-container">
		  <p>{extraireVille(removePostalCode(depart))}</p>
		  <div className="dot-container">
			<span className="dot">•</span>
			<span className="dot">•</span>
		  </div>
		  <p className='detailstrajet-distance' >{distance}</p>
		  <div className="dot-container">
			<span className="dot">•</span>
			<span className="dot">•</span>
		  </div>
		  <p>{(extraireVille(removePostalCode(arrivee)))}</p>
 <div className='bloc2'>
	<div className='row'>
		  <p className='adrhr'>
		  <span className='departure-label2'>Départ :</span>
		  <span className='departure-time2'>{extractHour(heure)}:{extractMinutes(heure)}</span>
		  </p>
	</div>

	<div className='row'>
		  <p className='adrde2'>
		  <span className='dtadr-label2'>De :</span>
		  <span className='adrdep-adr2'>{removeCountryFromAddress(removePostalCode(depart))}</span>
		</p>
	</div>	

	<div className='row'>
		<p className='adrarv2'>
		  <span className='dtadr2-label2'>À :</span>
		  <span className='adrarv-adr2'>{removeCountryFromAddress(removePostalCode(arrivee))}</span>
		</p>
	</div>	
	</div>
	
	{colis.length > 0 && (
  <div className='taille_poids'>
    <p>Taille du colis : {taille}</p>
    <p>Poids maximal : {poid_max} kg</p>
  </div>
)}
		<div className="services-detailstrajet">
		
		{checkbox1 === 1 && <img src={personne} alt="icone1" className="service-detailstrajet" />}
		{checkbox2 === 1 && <img src={anime} alt="icone2" className="service-detailstrajet" />}
		{checkbox3 === 1 && <img src={clim} alt="icone3" className="service-detailstrajet" />}
		{checkbox4 === 1 && <img src={prise} alt="icone4" className="service-detailstrajet" />}
		{checkbox5 === 1 && <img src={siege} alt="icone5" className="service-detailstrajet" />}
		</div>
		
		
		  <hr className="horizontal-line" />
		  <p className='price'> Prix total pour un passager</p>
		  <p className='price1'> {Math.round(tarif)}€</p>
		  <hr className="horizontal-line3" />
		  <div className="icons-profil">
			  <img src={profil} alt="iconprof2" className="iconprof"  onClick={() => window.location.href = '/profil'} />
			  <img src={versprofil} alt="iconversprofil" className="icongoprofil"  onClick={() => window.location.href = '/profil'}/>
			  <p className='conduct'  onClick={() => window.location.href = '/profil'} >{userName} {userLastName}</p>
			  <img src={verification} alt="verification" className="verification" />
			  <p className='cond'>Profil vérifié</p>
			</div>
			<hr className="horizontal-line4" />
			<img src={contact} alt="contact" className="tocontact" />
			<p className='cont'> contacter : {userName} {userLastName}  </p>
			<hr className="horizontal-line5" />
		  <img src={car} alt="iconprof" className="iconcar2"/>
		  <hr className="horizontal-line5" />
			<div className="continuer" onClick={handleReservationClick}>
							Réserver
			</div>
		</div>
		</div>
    );
};

export default DetailsTrajet;