import React, { useEffect, useState } from 'react';
import './colis.css';
import fleche from './fleche.png'
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const Colis1 = () => {
    
    const [selectedDate, setSelectedDate] = useState(null);
    const [time, setTime] = useState("");
    const [depart, setDepart] = useState('');
    const [arrivee, setArrivee] = useState('');
    const [tailleColis, setTailleColis] = useState('s');
    const [hauteur, setHauteur] = useState('');
    const [largeur, setLargeur] = useState('');
    const [longueur, setLongueur] = useState('');
    const [poidsMax, setPoidsMax] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [tarif, setTarif] = useState(0);
    const [prix, setPrix] = useState(0);
    const [colis_id, setColisId] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const id = params.get('id');
        setColisId(id);

        const adresseDepart = params.get('depart');
        setDepart(adresseDepart);
        const adresseArrivee = params.get('arrivee');
        setArrivee(adresseArrivee);
              
        const date = new Date(params.get('date'));
        setSelectedDate(date);
        const heure = params.get('heure');
        setTime(heure);

        const taille = params.get('taille');
        setTailleColis(taille);

        const tarif = params.get('tarif');
        setTarif(tarif);

        const poid_max = params.get('poid_max');
        setPoidsMax(poid_max);
     
       
      }, []);

  
      function formatDate(inputDateString) {
        const date = new Date(inputDateString);
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
      }

      const handleGoBack = () => {
        window.history.back();
      };

      const handleAjouterColis =  (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
    
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        const decodedPayload = JSON.parse(atob(encodedPayload));
        const userId = decodedPayload.sub;
    
        if (!depart || !arrivee || !selectedDate || !time || !poidsMax) {
          setErrorMessage('Veuillez remplir tous les champs obligatoires.');
          return;
      }

        const colisData = {
            depart: depart,
            arrivee: arrivee,
            date: selectedDate.toISOString().split('T')[0],
            heure: time,
            taille: tailleColis,
            poids_max: poidsMax,
            tarif: tarif,
            user_id: userId,
        };
    
        axios
            .post('https://test-api.bookair.space/api/ajouter-colis', colisData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setSuccessMessage('Colis ajouté avec succès !');
                setDialogOpen(true);
    
                setErrorMessage('');
            })
            .catch(error => {
              if (error.response && error.response.data && error.response.data.message) {
                if (error.response.status === 409) {
                    setErrorMessage('Ce colis existe déjà.');
                } else {
                    console.error(error.response.data.message);
                    setErrorMessage(error.response.data.message);
                }
            } else {
                console.error('Une erreur s\'est produite lors de la requête.');
            }
            });
    };
    
    const handleButtonClick1=() => {
      setDialogOpen(false);
      window.location.href = `/tripslist`;
    };

    function separateDistanceValue(distance) {
      const regex = /^(\d+)/;
      const match = distance.match(regex);
    
      if (match) {
        const value = parseInt(match[1]);
        return value;
      }
    
      return 0;
  
    }
    function arrondirPrix(prix) {
      const entier = Math.floor(prix); 
      const decimale = prix - entier; 
    
      
      const prixArrondi = decimale < 0.5 ? entier : Math.ceil(prix);
    
      return prixArrondi;
    }

    const incrementPrix = (prix) => {
      setTarif((prevPrix) => prevPrix + 1); 
      setPrix(prix + 1);
     // console.log(prix);
    };
  
    const decrementPrix = (prix) => {
      if (prix >= 1) {
        setTarif((prevPrix) => prevPrix - 1);
        
      }
    };
   
    const handleModifierColis = (e) => {
      e.preventDefault();
      const token = localStorage.getItem('token');
      const colisId = colis_id; 

      if (!poidsMax || !tailleColis || !colisId) {
          setErrorMessage('Veuillez remplir tous les champs nécessaires.');
          return;
      }

      const colisData = {
          poids_max: poidsMax,
          taille: tailleColis,
      };

      axios
          .put(`https://test-api.bookair.space/api/modifier-colis/${colisId}`, colisData, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          })
          .then(response => {
              setDialogOpen(true);
              setErrorMessage('');
          })
          .catch(error => {
              if (error.response && error.response.data && error.response.data.message) {
                  console.error(error.response.data.message);
                  setErrorMessage(error.response.data.message);
              } else {
                  console.error('Une erreur s\'est produite lors de la requête.');
              }
          });
  };

    return (
        <div className='colis-container'>
           <img src={fleche} alt="Retour" className="comeback5" onClick={handleGoBack} />
        <h1 className='colis-texte'>Créer un trajet de livraison de colis</h1>
        <form className='forms'>
          <label className='label-colis1'>
            Adresse de départ:
          </label>
            <input className='inputs' type="text" value={depart} onChange={(e) => setDepart(e.target.value)} required />
         
          <label className='label-colis1'>
            Adresse d'arrivée:
            </label>
            <input className='inputs' type="text" value={arrivee} onChange={(e) => setArrivee(e.target.value)} required />
          
          <label className='label-colis1'>
            Date:
            </label>

            <input className='inputs' type="text" value={formatDate(selectedDate)} required />
          
          <label className='label-colis1'>
            Heure:
            </label>

            <input className='inputs' type="time" value={time} required />
          
          <label className='label-colis1'>
            Taille du colis:
            </label>
            <select className='selector' value={tailleColis} onChange={(e) => setTailleColis(e.target.value)} required>
            <option value="xs">XS(200 x 140 x 50)</option>
              <option value="s">S(330 x 210 x 80)</option>
              <option value="m">M(230 x 130 x 120)</option>
              <option value="l">L(290 x 210 x 150)</option>
              <option value="xl">XL(400 x 275 x 195)</option>
            </select>
          
          <label className='label-colis1'>
            Poids maximum (en kg):
            </label>
            <input className='inputs' type="number" value={poidsMax} onChange={(e) => setPoidsMax(e.target.value)} required />
            <div className="vertical-align-container"> 
           <label className='label-colis2'> Prix total: </label>

               <p className='prxx1'> <button onClick={() => decrementPrix(tarif)} className="counter-button">
        -
      </button>
      <span className="counter-value">{arrondirPrix(tarif)}</span>
      <button onClick={() => incrementPrix(tarif)} className="counter-button">
        +
      </button></p> 
      </div>
          <button className='validate-colis' onClick={handleModifierColis} >Créer le trajet</button>
          
        </form>
        {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
        {successMessage && <span style={{ color: 'green' }}>{successMessage}</span>}
        {dialogOpen && (
      <div className="custom-dialog">
        <div className="dialog-content">
          <CheckCircleIcon style={{ color: 'green', fontSize: 48 }} />
          <Typography variant="h6">Trajet ajouté avec succès !</Typography>
          <Button onClick={handleButtonClick1}>OK</Button>
        </div>
      </div>
    )}
      </div>
    );
};

export default Colis1;