import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { useTransition, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './recherchecolis.css';
import image1 from './img1.png';
import image2 from './img2.png';
import image3 from './img3.png';
import axios from 'axios';

const RechercheColis = ({ google }) => {

    const images = [
        { id: 1, src: image1, width: '700px', height: '550px' },
        { id: 2, src: image2, width: '700px', height: '550px' },
        { id: 3, src: image3, width: '700px', height: '550px' },
      ];

      const [currentImageIndex, setCurrentImageIndex] = useState(0);
      const [iterationCount, setIterationCount] = useState(0);
      const [isHovered, setIsHovered] = useState(false);
      const [depart, setDepart] = useState('');
      const [arrivee, setArrivee] = useState('');
      const [date, setDate] = useState('');
      const [nbPersonne, setNbPersonne] = useState('');
      const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    const timer = setTimeout(() => {
      if (iterationCount < 3 && !isHovered) {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIterationCount((count) => count + 1);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, iterationCount, images.length, isHovered]);

  const handleMouseEnter = () => {
    setCurrentImageIndex(0);
    setIterationCount(0);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const transitions = useTransition(currentImageIndex, {
    from: { opacity: 0, position: 'absolute', left: 0, top: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    // Initialisation de l'autocomplétion pour le champ de départ
    const departAutocomplete = new google.maps.places.Autocomplete(
      document.getElementById('depart-input')
    );

    departAutocomplete.addListener('place_changed', () => {
      const place = departAutocomplete.getPlace();
      setDepart(place.formatted_address);
    });

    // Initialisation de l'autocomplétion pour le champ d'arrivée
    const arriveeAutocomplete = new google.maps.places.Autocomplete(
      document.getElementById('arrivee-input')
    );

    arriveeAutocomplete.addListener('place_changed', () => {
      const place = arriveeAutocomplete.getPlace();
      setArrivee(place.formatted_address);
    });
  }, [google]);

  const handleRechercher = () => {
    if (!depart || !arrivee || !date ) {
        setErrorMessage('Veuillez remplir tous les champs');
      } 
  else{	  	
  setErrorMessage('');	
  window.location.href = `/result2?depart=${depart}&arrivee=${arrivee}&date=${date}&`;
} 
};

    return (
        <div className="home-container">
            
      <div className="images-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {transitions((style, index) => (
          <animated.img
            key={images[index].id}
            src={images[index].src}
            alt={`Image ${index + 1}`}
            className="animated-image"
            style={ {...style, width: images[index].width, height: images[index].height }}
          />
        ))}

      </div>
      <div className="content-container">
        <div>
          <h1 className="titlecolis"> Trouvez des trajets pour la livraison de votre colis en toute sécurité </h1>
          <div className="search-bar">
            <div className="search-input-container-colis">
              <input className="search-input with-icon1" type="text" placeholder="Départ" value={depart}
                  onChange={(e) => setDepart(e.target.value)} id="depart-input"/>
            </div>
            <hr className="search-separator" />
            <div className="search-input-container">
              <input className="search-input with-icon2" type="text" placeholder="Arrivée" value={arrivee}
                    onChange={(e) => setArrivee(e.target.value)} id="arrivee-input"/>
            </div>
            <hr className="search-separator" />
            <div className="search-input-container">
              <input className="search-input with-icon3" type="date" placeholder="Date" value={date}
                   onChange={(e) => setDate(e.target.value)} />
            </div>
            <button className="search-button" onClick={handleRechercher}>
              Rechercher
            </button>
          </div>
          {errorMessage && <p className="error-message-colis">{errorMessage}</p>}

       <div className='search_packages'>
      <h1>
        <Link to="/step1?mode=1&annonce=1" style={{ textDecoration: 'underline' }}>
            Déposer votre annoce pour envoyer un colis ?
        </Link>
      </h1>
    </div>

          <h1 className="subtitle1">Envoyez des colis à vos proches ou à des clients en toute simplicité</h1>
					<h1 className="subtitle2">
                    Des tarifs compétitifs et des options de livraison flexibles
					</h1>
         
        </div>
        </div>
        </div>
    );
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg'
  })(RechercheColis);