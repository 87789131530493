import React, { useState, useEffect } from 'react';
import './edit6.css'; 
import axios from 'axios';

const Edit6 = () => {
  const [id, setId] = useState('');
  const [tarif, setTarif] = useState(0);
  const [mode, setMode] = useState('');  

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    const mode = params.get('mode');
    setMode(mode);

    setId(id);
    console.log(id);

    const token = localStorage.getItem('token'); 


    if (mode === '0') {
    axios.get(`https://test-api.bookair.space/api/getTarif/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setTarif(response.data.tarif);
        
      })
      .catch(error => {
        console.error('Error fetching tarif:', error);
      });
    }
    else{
      axios.get(`https://test-api.bookair.space/api/getTarifColis/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setTarif(Math.round(response.data.tarif));
      })
      .catch(error => {
        console.error('Error fetching tarif:', error);
      });
    }

  }, []);

  const handleIncrease = () => {
    setTarif(prevTarif => prevTarif + 1);
  };

  const handleDecrease = () => {
    if (tarif > 0) {
      setTarif(prevTarif => prevTarif - 1);
    }
  };

  const handleConfirm = async () => {

    console.log("le mode",mode);
    if (mode === '0') {
  
    try {
     
      const token = localStorage.getItem('token');

      
      const response = await axios.post(
        `https://test-api.bookair.space/api/updateTarif/${id}`,
        {
          tarif: tarif,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Tarif updated successfully:', response.data);
      window.location.href = '/tripslist';
    } catch (error) {
      console.error('Error updating tarif:', error);
    }

  }

 else  {

  try {
     
    const token = localStorage.getItem('token');

    
    const response = await axios.post(
      `https://test-api.bookair.space/api/updateTarifColis/${id}`,
      {
        tarif: tarif,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Tarif updated successfully:', response.data);
    window.location.href = '/tripslist';
  } catch (error) {
    console.error('Error updating tarif:', error);
  }

 }

  };

  return (
    <div className="edit6-container">
      <div className="edit6-content">
        <h2>Modifier le Tarif</h2>
        <div className="tarif-container">
          <button onClick={handleDecrease}>-</button>
          <span className="tarif">{tarif} €</span>
          <button onClick={handleIncrease}>+</button>
        </div>
        <button className="confirm-button" onClick={handleConfirm}>Confirmer</button>
      </div>
    </div>
  );
};

export default Edit6;
