import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, Polyline } from 'google-maps-react';
import { Autocomplete } from 'google-maps-react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import fleche from './fleche.png'
import "./etape4.css"

const DialogBox = ({ isOpen, onClose }) => {
  return (
    <div className={`dialog-box ${isOpen ? 'open' : ''}`}>
      <div className="dialog-content">
        <p>Veuillez entrer une adresse précise.</p>
        <button className='fermer' onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

const Edit5 = ({ google }) => {
  const [depart, setDepart] = useState('');
  const [arrivee, setArrivee] = useState('');
  const [distance, setDistance] = useState('');
  const [routes, setRoutes] = useState([]);
  const [routeCities, setRouteCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [visibleCities, setVisibleCities] = useState(10);
  const [showAddEscale, setShowAddEscale] = useState(false);
  const [newEscale, setNewEscale] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const escaleAutocomplete = new google.maps.places.Autocomplete(
      document.getElementById('escale-input'),
      {
        componentRestrictions: { region: 'eu' }
      }
    );
  
    escaleAutocomplete.addListener('place_changed', () => {
      const place = escaleAutocomplete.getPlace();
      setNewEscale(place.formatted_address);
    });
  }, [google]);
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adresseDepart = params.get('adressedepart');
    const adresseArrivee = params.get('arrivee');
    const communes = JSON.parse(params.get('communes'));
    const distance = params.get('distance');

    setDepart(adresseDepart);
    setArrivee(adresseArrivee);
    setDistance(distance);
    setRouteCities(communes);

    console.log(adresseArrivee);
    console.log(adresseDepart);
    console.log(communes);
    console.log(distance);
  }, []);

  const handleCitySelection = (city) => {
    if (selectedCities.includes(city)) {
      setSelectedCities(selectedCities.filter((c) => c !== city));
    } else {
      setSelectedCities([...selectedCities, city]);
    }
    console.log(selectedCities); 
  };
  
 
  const renderRouteCities = () => {
    const visibleRouteCities = routeCities.slice(routeCities.length - visibleCities);
  
    return visibleRouteCities.map((city, index) => (
      <div key={index} className="route-city-item">
        <span className="city">{city}</span>
        <input
          type="checkbox"
          name="city"
          className="checkbox-btn"
          value={city}
          checked={selectedCities.includes(city)}
          onChange={() => handleCitySelection(city)}
        />
      </div>
    ));
  };

  const handleSaveEscale = () => {
    if (newEscale.trim() !== '' && adresseEstPrecise(newEscale)) {
      setRouteCities([...routeCities, newEscale]);
      setNewEscale('');
      setShowAddEscale(false);
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleShowMore = () => {
    if (visibleCities < routeCities.length) {
      setVisibleCities(visibleCities + 10);
    }
  };

  const handleShowLess = () => {
    if (visibleCities > 10) {
      setVisibleCities(visibleCities - 10);
    }
  };

  const handleAddEscale = () => {
    setShowAddEscale(true);
  };

  const handleEscaleInputChange = (e) => {
    setNewEscale(e.target.value);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewEscale('');
    setShowAddEscale(false);
  };

 
  const handleContinuer = () => {
    const params = new URLSearchParams();
    params.append('adressedepart', depart);
    params.append('arrivee', arrivee);
    params.append('distance', distance);
    params.append('communes', JSON.stringify(selectedCities));
      
    const queryString = `?${params.toString()}`;
    window.location.href = `/step5${queryString}`;
  };

  const adresseEstPrecise = (adresse) => {
    const adresseParts = adresse.split(',').map(part => part.trim());
    const [ville, pays] = adresseParts;
  
    if (adresseParts.length === 2) {
      return false;
    }
  
    if (ville && !pays) {
      return false;
    }
  
    if (!ville && pays) {
      return false;
    }

    return true;
  };
  const handleGoBack = () => {
    window.history.back();
  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);

  
  return (
    <div className="etape4">
       <img src={fleche} alt="Retour" className="comeback5" onClick={handleGoBack} />
      <label htmlFor="depart" className="labeletape4">
        Ajoutez des étapes pour trouver plus de passagers?
      </label>
      <div className="route-cities-container">
        {renderRouteCities()}
        {showAddEscale && !isDialogOpen ? (
          <div className="route-city-item">
            <input
              type="text"
              className="escale-input"
              value={newEscale}
              onChange={handleEscaleInputChange}
              id="escale-input"
              placeholder="Escale"
            />
            <button className="escale-btn" onClick={handleSaveEscale}>
              Ajouter
            </button>
          </div>
        ) : (
          !isDialogOpen && (
            <div className="add-escale-container">
              <span className="add-escale-text" onClick={handleAddEscale}>
                Ajouter une étape
              </span>
            </div>
          )
        )}
        {routeCities.length > 10 && (
          <div className="affichage-container">
            {visibleCities < routeCities.length ? (
              <span className="affichage" onClick={handleShowMore}>
                Afficher plus <FaAngleDown className="icon" />
              </span>
            ) : (
              <span className="affichage" onClick={handleShowLess}>
                Afficher moins <FaAngleUp className="icon" />
              </span>
            )}
          </div>
        )}
      </div>
      {isDialogOpen && (
        <DialogBox isOpen={isDialogOpen} onClose={handleCloseDialog} />
      )}

      <button onClick={handleContinuer} className='tocontinuate'>Continuer</button>

    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg',
})(Edit5);
