import React, { useEffect, useState } from 'react';

const Edit3 = () => {
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          return;
        }
    
        const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
        const currentTime = new Date();
    
        if (currentTime >= tokenExpirationDate) {
          window.location.href = '/login';
        }
      }, []);

    return (
        <div>
            
        </div>
    );
};

export default Edit3;