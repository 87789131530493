import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Layout from './Layout'
import Xservices from './Xservices'
import Xcontact from './Xcontact'
import Error from '../../_utils/Error'
import Infos from './Infos'
import Profil from '../private/Profile'
import Accueil from '../private/Accueil'
import Mesreservations from '../private/Mesreservation'
import Recherche from './Recherche'
import Recherchefromaccueil from '../private/Recherchefromaccueil'
import ModificationTrajet from '../private/ModificationTrajet'
import TrajetDetails from './TrajetDetails'
import DetailsTrajet from '../private/DetailsTrajet'
import Etape1 from '../private/Etape1'
import Etape2 from '../private/Etape2'
import Etape3 from '../private/Etape3'
import Etape4 from '../private/Etape4'
import Etape5 from '../private/Etape5'
import Etape6 from '../private/Etape6'
import Etape7 from '../private/Etape7'
import Paiement from '../private/Paiement'
import Cartebancaire from '../private/Cartebancaire'
import ListeTrajets from '../private/ListeTrajets'
import TripDetails from '../private/TripDetails'
import EditTrip from '../private/EditTrip'
import Vehicule from '../private/Vehicule'
import Messages from '../private/Messages'
import AddVehicule from '../private/AddVehicule'
import Drivinglicence from '../private/Drivinglicence'
import Idcard from '../private/Idcard'
import Profile from '../private/Profil'
import Edit1 from '../private/Edit1'
import Edit2 from '../private/Edit2'
import Edit3 from '../private/Edit3'
import Edit4 from '../private/Edit4'
import Edit5 from '../private/Edit5'
import Edit6 from '../private/Edit6'
import Register from './Register'
import ProfilConducteur from '../private/Profilconducteur'
import ColisTrajet from '../private/ColisTrajet'
import Colis from '../private/Colis'
import Colis1 from '../private/Colis1'
import Editcolis from '../private/Editcolis'
import RechercheColis from '../private/RechercheColis'
import HomeRechercheColis from './HomeRechercheColis'
import ResultatColis from '../private/ResultatColis'
import ReslutatHomeColis from './ReslutatHomeColis'
import Annonce from '../private/Annonce'

const PublicRoute = () => {
	return (
		<div>
			<Routes>
				<Route element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/register" element={<Xservices />} />
					<Route path="/login" element={<Xcontact />} />
					<Route path="/infos" element={<Infos />} />
					<Route path="/profil" element={<Profil />} />
					<Route path="/profiltest" element={<Profile />} />
					<Route path="/homepage" element={<Accueil />} />
					<Route path="/mesreservations" element={<Mesreservations />} />
					<Route path="/search1" element={<Recherche />} />
					<Route path="/search2" element={<Recherchefromaccueil />} />
					<Route path="/modificationtrajets" element={<ModificationTrajet />} />
					<Route path="/tripdetails1" element={<TrajetDetails/>} />
					<Route path="/tripdetails2" element={<DetailsTrajet/>} />
					<Route path='tripslist' element={<ListeTrajets/>} />
					<Route path="/step1" element={<Etape1/>} />
					<Route path="/step2" element={<Etape2/>} />
					<Route path="/step3" element={<Etape3/>} />
					<Route path="/step4" element={<Etape4/>} />
					<Route path='/step5' element={<Etape5/>} />
					<Route path='/step6' element={<Etape6/>} />
					<Route path='/step7' element={<Etape7/>} />
					<Route path='/editrip' element={<EditTrip/>} />
					<Route path='/editpackage' element={<Editcolis/>} />
					<Route path='/searchpackage' element={<RechercheColis/>} />
					<Route path='/edit1' element={<Edit1/>} />
					<Route path='/edit2' element={<Edit2/>} />
					<Route path='/edit3' element={<Edit3/>} />
					<Route path='/edit4' element={<Edit4/>} />
					<Route path='/edit5' element={<Edit5/>} />
					<Route path='/edit6' element={<Edit6/>} />
					<Route path='/colis' element={<Colis/>} />
					<Route path='/colis1' element={<Colis1/>} />
					<Route path='/annonce' element={<Annonce/>} />
					<Route path='/homesearchpackage' element={<HomeRechercheColis/>} />
					<Route path='/paiement' element= {<Paiement/>} />
					<Route path='/tripdetails3' element= {<TripDetails/>} />
					<Route path='/cartebancaire' element = {<Cartebancaire/>} />
					<Route path='/vehicule' element = {<Vehicule/>} />
					<Route path='/messages' element = {<Messages/>} />
					<Route path='/addvehicule' element = {<AddVehicule/>} />
					<Route path='/drivinglicence' element = {<Drivinglicence/>} />
					<Route path='/idcard' element = {<Idcard/>} />
					<Route path='/colistrajet' element = {<ColisTrajet/>} />
					<Route path='/register1' element = {<Register/>} />
					<Route path='/profilconducteur' element = {<ProfilConducteur/>} />
					<Route path='/result1' element = {<ReslutatHomeColis/>} />
					<Route path='/result2' element = {<ResultatColis/>} />
					<Route path="*" element={<Error />} />
				</Route>
			</Routes>
		</div>
	)
}

export default PublicRoute
