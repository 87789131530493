import React, { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import DatePicker from 'react-datepicker';
import { GoogleApiWrapper} from 'google-maps-react';
import 'react-datepicker/dist/react-datepicker.css';
import queryString from 'query-string';
import './xservice.css'
import axios from 'axios'

import image1 from './image1.svg'
import image2 from './image2.svg'
import image3 from './image3.svg'
import image4 from './image4.svg'


const Register = ({ google }) => {

    const [BirthDate, setBirthDate] = useState(null);

    const images = [
		{ id: 1, src: image1, width: '700px', height: '550px' },
		{ id: 2, src: image2, width: '700px', height: '550px' },
		{ id: 3, src: image3, width: '700px', height: '550px' },
		{ id: 4, src: image4, width: '700px', height: '550px' },
	]
	const [currentImageIndex, setCurrentImageIndex] = useState(0)
	const [iterationCount, setIterationCount] = useState(0)
	const [isHovered, setIsHovered] = useState(false)
    const [selectedGender, setSelectedGender] = useState('');
    const [adresse, setAdresse] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
	  const [email, setEmail] = useState('');
	  const [numeroTel, setNumeroTel] = useState('');
	  const [motDePasse, setMotDePasse] = useState('');
    const [confirmationMotDePasse, setConfirmationMotDePasse] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [dateDeNaissance, setDateDeNaissance] = useState('');
    const [genre, setGenre] = useState('homme');
    const [ville, setVille] = useState('');
    const [CodePostale, setCodePostale] = useState('');

    

    const handleDateChange = (date) => {
        setDateDeNaissance(date);
      };

      const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
      };

      useEffect(() => {
        
        const searchParams = new URLSearchParams(window.location.search);
        const decodedNom = decodeURIComponent(searchParams.get('nom'));
        const decodedPrenom = decodeURIComponent(searchParams.get('prenom'));
        const decodedEmail = decodeURIComponent(searchParams.get('email'));
        const decodedNumeroTel = decodeURIComponent(searchParams.get('numeroTel'));
        const decodedMotDePasse = decodeURIComponent(searchParams.get('motDePasse'));
        const decodedConfirmationMotDePasse = decodeURIComponent(searchParams.get('confirmationMotDePasse'));

        setNom(decodedNom);
        setPrenom(decodedPrenom);
        setEmail(decodedEmail);
        setNumeroTel(decodedNumeroTel);
        setMotDePasse(decodedMotDePasse);
        setConfirmationMotDePasse(decodedConfirmationMotDePasse);
    
        console.log('Nom:', decodedNom);
        console.log('Prénom:', decodedPrenom);
        console.log('Email:', decodedEmail);
        console.log('Numéro de téléphone:', decodedNumeroTel);
        console.log('Mot de passe:', motDePasse);
      }, []);


      
	const sendPost = () => {
		if (motDePasse !== confirmationMotDePasse) {
			setIsPasswordMatch(false)
            console.log("okokokokokokiiiiiok");
			return
		}
		return axios
			.post('https://test-api.bookair.space/api/register', {
				name: nom,
				firstName: prenom,
				email: email,
				phoneNumber: numeroTel,
				password: motDePasse,
				password_confirmation: confirmationMotDePasse,
                date_of_birth: dateDeNaissance,
                gender: selectedGender,
                address: adresse,
			})
			.then((response) => {
				console.log(response)
				const isSuccess = true; 
                const redirectUrl = `/login?success=${isSuccess}`; 
                window.location.href = redirectUrl;
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.errors) {
					const validationErrors = Object.values(error.response.data.errors).flat()
					const errorMessage = validationErrors.join(' ')
					setErrorMessage(errorMessage)
				} else {
					console.log(error)
				}
			})
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			if (iterationCount < 3 && !isHovered) {
				setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
				setIterationCount((count) => count + 1)
			}
		}, 5000)

		return () => clearTimeout(timer)
	}, [currentImageIndex, iterationCount, images.length, isHovered])

	const handleMouseEnter = () => {
		setCurrentImageIndex(0)
		setIterationCount(0)
		setIsHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}

	const transitions = useTransition(currentImageIndex, {
		from: { opacity: 0, position: 'absolute', left: 0, top: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	})

    useEffect(() => {
       
        const departAutocomplete = new google.maps.places.Autocomplete(
          document.getElementById('adresse-input'),
          {
            componentRestrictions: { region: 'eu' }
          }
        );
      
        departAutocomplete.addListener('place_changed', () => {
          const place = departAutocomplete.getPlace();
          setAdresse(place.formatted_address);
          extractCityAndPostalCode(place.formatted_address);
        });
      }, [google]);

      const extractCityAndPostalCode = (address) => {
        const components = address.split(', ');
    
        if (components.length >= 3) {
            const postalCode = components[components.length - 2].split(' ')[0];
            const city = components[components.length - 3];

            setCodePostale(postalCode);
        }
    };
    function extraireVille(adresse) {
        const adresseSplit = adresse.split(',');
        if (adresseSplit.length >= 2) {
          const ville = adresseSplit[adresseSplit.length - 2].trim();
          return ville;
        } else {
          return null;
        }
      }
  
      function removePostalCode(address) {
        return address.replace(/\d+/, '').trim();
      }

    return (
        <div>
        <div
            className="images-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {transitions((style, index) => (
                <animated.img
                    key={images[index].id}
                    src={images[index].src}
                    alt={`Image ${index + 1}`}
                    className="animated-image"
                    style={{
                        ...style,
                        width: images[index].width,
                        height: images[index].height,
                    }}
                />
            ))}
        </div>
        <h1 className="title3">
            {' '}
            Partagez vos trajets et économisez de l'argent en covoiturant avec d'autres
            voyageurs{' '}
        </h1>
        <div className="cover1">
            <h1>Informations complémentaires :</h1>
            <div className="input-container">
            <DatePicker
              selected={BirthDate}
              onChange={date => setBirthDate(date)} 
              placeholderText="Date de naissance"
              dateFormat="dd/MM/yyyy"
              isClearable
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              scrollableMonthYearDropdown
              shouldCloseOnSelect={false}
            
      />

      <select className='select-gender' 
      value={selectedGender} 
      onChange={handleGenderChange}>
        <option value="">Sélectionnez le genre</option>
        <option value="homme">Homme</option>
        <option value="femme">Femme</option>
      </select>
</div>

<input
        className='adresse-user'
		type="text"
		placeholder="Adresse"
        id="adresse-input"
					/>


<div className='input-container'>

         <input
          className='ville'
		  type="text"
		  placeholder="Ville"	  
          value={adresse ? removePostalCode(extraireVille(adresse)) : ''}
		  />

		  <input
            className='code-postale'
			type="text"
			placeholder="Code postale"
            value={CodePostale}
            onChange={(e) => setAdresse(e.target.value)}
		  />
    </div>                    
 
        <input
          type="checkbox"
          className='condition'
        />
      <label 
      className='label-condition'
      >
        J'accepte les conditions générales
      </label>


            <div className="login-btn1" onClick={sendPost}>
                Créer mon compte
            </div>
        </div>
    </div>
    );
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg'
  })(Register);