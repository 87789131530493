import React from 'react';
import colis from './colis.png'
import passager from '../Public/passager.png'
import './colistrajet.css'

const ColisTrajet = () => {

    const handlePassagerClick = () => {
        window.location.href = '/step1?mode=0';
      };
    
      const handleColisClick = () => {
        window.location.href = '/step1?mode=1';
      };

      
    return (
        <div>
             <div className='paiement'>
             <label htmlFor="paiement" className="label1">
             Sélectionnez votre mode de publication de trajet
          </label>
          <div className="payment-options">
        <button className="choise-button"  onClick={handlePassagerClick}>
        <img src={passager} alt="visa" className="passager" />   
        Je souhaite récupérer des passagers
        
        <div className="card-logos">
        </div>
      </button>
      <hr className="horizontal-line8" />
      <button className="choise-button" onClick={handleColisClick} >
      <img src={colis} alt="visa" className="colis" />   
      Je souhaite faire livrer un colis
      
      </button>
      </div>
        </div>
        </div>
    );
};

export default ColisTrajet;