import React, { useState, useEffect, useRef } from 'react'
import { Map, GoogleApiWrapper, Marker, Polyline } from 'google-maps-react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import personne from './2pers.png'
import anim from './anim.png'
import siege from './siege.png'
import prise from './prise.png'
import clime from './clim.png'
import iconImage from './marque.png'


const ModificationTrajet = ({ google }) => {

	const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
  
    const id = searchParams.get('id');
    const olddepart = searchParams.get('depart');
    const oldarrivee = searchParams.get('arrivee');
    const olddate = searchParams.get('date');
    const oldheure = searchParams.get('heure');
	const oldnbpersonne = searchParams.get('nb_personne');

    const [depart, setDepart] = useState('');
	const [arrivee, setArrivee] = useState('');
    const [departCoords, setDepartCoords] = useState(null);
    const [arriveeCoords, setArriveeCoords] = useState(null);

    const mapRef = useRef(null);
	const [confirmModification, setConfirmModification] = useState(false);

	const [routeCoords, setRouteCoords] = useState([]);
    const [polylineOptions, setPolylineOptions] = useState({
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
      });

	  useEffect(() => {
		fetchCoordinates();
		fetchRouteCoordinates();
	  }, [depart, arrivee]);

	
	  const fetchRouteCoordinates = () => {
		if (olddepart && oldarrivee) {
		  const directionsService = new google.maps.DirectionsService();
		  const request = {
			origin: olddepart,
			destination: oldarrivee,
			travelMode: 'DRIVING',
		  };
	
		  directionsService.route(request, (result, status) => {
			if (status === 'OK') {
			  const route = result.routes[0];
			  const routePath = route.overview_path.map(point => ({
				lat: point.lat(),
				lng: point.lng(),
			  }));
			  setRouteCoords(routePath);
			} else {
			  console.error('Directions request failed due to ' + status);
			}
		  });
		}
	  };

	  const fetchCoordinates = () => {
		if (olddepart && oldarrivee) {
		  const geocoder = new google.maps.Geocoder();
		  geocoder.geocode({ address: olddepart }, (results, status) => {
			if (status === 'OK' && results.length > 0) {
			  const location = results[0].geometry.location;
			  setDepartCoords({ lat: location.lat(), lng: location.lng() });
			}
		  });
	  
		  geocoder.geocode({ address: oldarrivee }, (results, status) => {
			if (status === 'OK' && results.length > 0) {
			  const location = results[0].geometry.location;
			  setArriveeCoords({ lat: location.lat(), lng: location.lng() });
			}
		  });
		}
	  };


	  useEffect(() => {
		if (departCoords && arriveeCoords) {
		  const bounds = new google.maps.LatLngBounds();
		  bounds.extend(departCoords);
		  bounds.extend(arriveeCoords);
		  mapRef.current.map.fitBounds(bounds);
		}
	  }, [departCoords, arriveeCoords]);
	  
	  
	
	const [date, setDate] = useState(olddate);
	const [heure, setHeure] = useState(oldheure);
	
    const [nb_personne, setNbPersonne] = useState('');
	const [tarif, setTarif] = useState('');

	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(false);
	const [checkbox4, setCheckbox4] = useState(false);
	const [checkbox5, setCheckbox5] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const handleCheckboxChange1 = (e) => {
		setCheckbox1(e.target.checked);
	  };
	
	  const handleCheckboxChange2 = (e) => {
		setCheckbox2(e.target.checked);
	  };
	
	  const handleCheckboxChange3 = (e) => {
		setCheckbox3(e.target.checked);
	  };
	
	  const handleCheckboxChange4 = (e) => {
		setCheckbox4(e.target.checked);
	  };
	
	  const handleCheckboxChange5 = (e) => {
		setCheckbox5(e.target.checked);
	  };

	  useEffect(() => {
		const fetchTripDetails = async () => {
			
		  try {
			const token = localStorage.getItem('token'); 
			const config = {
			  headers: {
				Authorization: `Bearer ${token}`, 
			  },
			};
			console.log(id)
			const response = await axios.get(`https://test-api.bookair.space/api/trajet/${id}`, config);
			const { tarif, nb_personne, checkbox1, checkbox2, checkbox3, checkbox4, checkbox5} = response.data;
			setTarif(tarif);
			setNbPersonne(nb_personne);
			setCheckbox1(checkbox1);
			setCheckbox2(checkbox2);
			setCheckbox3(checkbox3);
			setCheckbox4(checkbox4);
			setCheckbox5(checkbox5);
			
		  } catch (error) {
			console.error('Erreur lors de la récupération des détails du trajet :', error);
		  }
		};
	
		fetchTripDetails();
	  }, [id]);

	  const handleModification = async () => {
		setConfirmModification(true);
	  };

	  const handleConfirmation = async () => {

		try {

		  const token = localStorage.getItem('token');
		  const config = {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  };
		  
		  const updatedData = {
			date: date,
			heure: heure,
			tarif: tarif,
			nb_personne: nb_personne,
			checkbox1: checkbox1,
			checkbox2: checkbox2,
			checkbox3: checkbox3,
			checkbox4: checkbox4,
			checkbox5: checkbox5,
		  };
   
         
		  const response = await axios.post(`https://test-api.bookair.space/api/trajet/${id}/update`, updatedData, config);
	
		  if (response.status === 200) {
			setSuccessMessage('Le trajet a été modifié avec succès.');
		  } else {
			setErrorMessage('Erreur lors de la modification du trajet.');
		  }
		} catch (error) {
		  console.error('Erreur lors de la modification du trajet :', error);
		  setErrorMessage('Une erreur s\'est produite lors de la modification du trajet.');
		}
		
	  };

    return (
        <div className="container-ajout">
        <div className="left-text">Modifier le trajet :</div>
        <div>
            <p className="txt1">Départ :</p>
            <input className="depart" type="text" value={olddepart} readOnly />
        </div>
        <div>
            <p className="txt2">Arrivée :</p>
            <input className="arrivee" type="text" value={oldarrivee} readOnly />
        </div>
        <div className="bottom-section">
            <div>
                <p className="txt3">Date de départ :</p>
                <input className="date" type="date" required  value={date} onChange={(e) => setDate(e.target.value)}/>
            </div>
            <div>
                <p className="txt4">Heure de départ:</p>
                <input className="heure" type="time" required  value={heure} onChange={(e) => setHeure(e.target.value)}/>
            </div>
        </div>

        <div className="bottom-bottom-section">
            <div>
                <p className="txt5">Nombre de place:</p>
                <input className="nbplace" type="text" required  value={nb_personne} onChange={(e) => setNbPersonne(e.target.value)}/>
            </div>
            <div>
                <p className="txt6">Tarif:</p>
                <input className="tarif" type="text" required  value={tarif} onChange={(e) => setTarif(e.target.value)}/>
            </div>
        </div>
        <p className="txt7">Services et équipements:</p>

        <div className="icon1-section">
            <img src={personne} alt="icone" className="icone" />
            <span className="eq1">Max 2 à l'intérieur</span>
            <input type="checkbox" className="checkboxl1" checked={checkbox1} onChange={handleCheckboxChange1} />
        </div>

        <div className="icon2-section">
            <img src={anim} alt="icone" className="icone2" />
            <span className="eq2">Animaux de compagnie</span>
            <input type="checkbox" className="checkboxl2" checked={checkbox2} onChange={handleCheckboxChange2}/>
        </div>

        <div className="icon3-section">
            <img src={siege} alt="icone" className="icone3" />
            <span className="eq3">Siège inclinable</span>
            <input type="checkbox" className="checkboxl3" checked={checkbox3} onChange={handleCheckboxChange3} />
        </div>

        <div className="icon4-section">
            <img src={clime} alt="icone" className="icone4" />
            <span className="eq4">Climatisation</span>
            <input type="checkbox" className="checkboxl4" checked={checkbox4} onChange={handleCheckboxChange4}/>
        </div>

        <div className="icon5-section">
            <img src={prise} alt="icone" className="icone5" />
            <span className="eq5">Prises électriques</span>
            <input type="checkbox" className="checkboxl5" checked={checkbox5} onChange={handleCheckboxChange5}/>
        </div>
         <div>
         
        <button className="ajout" onClick={handleModification}>
        Modifier
      </button>
      </div>
      {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
      {successMessage && <span style={{ color: 'green' }}>{successMessage}</span>}

        <div>

		<Map
  className='map-container'
  ref={mapRef}
  style={{ width: '750px', height: '700px' }}
  google={google}
  zoom={10}
  initialCenter={{ lat: 48.8566, lng: 2.3522 }}
>
  <Marker
    position={departCoords}
    icon={{
      url: iconImage,
      scaledSize: new google.maps.Size(40, 40),
    }}
  />

  <Marker
    position={arriveeCoords}
    icon={{
      url: iconImage,
      scaledSize: new google.maps.Size(40, 40),
    }}
  />

  {routeCoords.length > 0 && <Polyline path={routeCoords} options={polylineOptions} />}
</Map>
        </div>

	{confirmModification && (
	<div className="modal">
	<div className="modal-content">
	  <p>Êtes-vous sûr de vouloir modifier les infos de ce trajet ?</p>
	  <button className='butt1' onClick={() => handleConfirmation}>Je confirme</button>
	  <button className='butt2' onClick={() => setConfirmModification(false)}>Annuler</button>
	</div>
	</div>
)}
    </div>

	
)
}
    
export default GoogleApiWrapper({
	apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg'
  })(ModificationTrajet);
  