import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './etape2.css';
import fleche from './fleche.png';

const DialogBox = ({ isOpen, onClose }) => {
  return (
    <div className={`dialog-box ${isOpen ? 'open' : ''}`}>
      <div className="dialog-content">
        <p>Veuillez entrer une adresse précise.</p>
        <button className='fermer' onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

const Etape2 = ({ google }) => {
  const [adressedepart, setAdresseDepart] = useState('');
  const [arrivee, setArrivee] = useState('');
  const [markerPosition, setMarkerPosition] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [markerAddress, setMarkerAddress] = useState('');
  const [mode, setMode] = useState('');
  const [annonce, setAnnonce] = useState('');

  let labelText;
  if (mode === '0') {
    labelText = "Où allez-vous ?";
  } else if (mode === '1') {
    labelText = "Quelle est l'adresse d'arrivée du colis?";
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adressedepart = params.get('depart');
    const mode = params.get('mode');
    const annonce = params.get('annonce');
    setAnnonce(annonce);

    setAdresseDepart(adressedepart);
    setMode(mode);
    console.log("l'annonce égale ààà", annonce);

    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);

  const setupAutocomplete = () => {
    const input = document.getElementById('arrivee-input');
    const autocomplete = new google.maps.places.Autocomplete(input, { componentRestrictions: { region: 'eu' } });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        const location = place.geometry.location;
        if (location && location.lat && location.lng) {
          const newAddress = place.formatted_address;
          setArrivee(newAddress);
          setMarkerPosition({ lat: location.lat(), lng: location.lng() });
        } else {
          console.log('Invalid coordinates received from place:', place);
        }
      } else {
        console.log('No valid geometry found in place:', place);
      }
    });
  };

  useEffect(() => {
    setupAutocomplete();
  }, []);

  const handleInputChange = (e) => {
    setArrivee(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (arrivee) {
      if (adresseEstPrecise(arrivee)) {
        let  queryString = `?adressedepart=${encodeURIComponent(adressedepart)}&arrivee=${encodeURIComponent(arrivee)}&mode=${encodeURIComponent(mode)}`;
        
        if (annonce === '1') {
          queryString += '&annonce=1';
        }
        window.location.href = `/step3${queryString}`;
      } else {
        setIsDialogOpen(true); 
      }
    }

  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const adresseEstPrecise = (adresse) => {
    const adresseParts = adresse.split(',').map(part => part.trim());
    const [ville, pays] = adresseParts;

    if (adresseParts.length === 2) {
      return false;
    }

    if (ville && !pays) {
      return false;
    }
    if (!ville && pays) {
      return false;
    }

    return true;
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleMarkerDragEnd = (t, map, coord) => {
    if (coord && coord.latLng) {
      const { lat, lng } = coord.latLng;
      console.log("Coordinates:", lat(), lng());
      setMarkerPosition({ lat: lat(), lng: lng() });

      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: lat(), lng: lng() };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const newAddress = results[0].formatted_address;
          setMarkerAddress(newAddress);
          setArrivee(newAddress);
        }
      });
    } else {
      console.log("Invalid coordinates:", coord);
    }
  };


  return (
    <div>
      <div className='etape'>
        <img src={fleche} alt="Retour" className="comeback3" onClick={handleGoBack} />
        <form onSubmit={handleFormSubmit}>
          <div className="etape">
            <label htmlFor="arrivee" className="step2-label">
            {labelText}
            </label>
            <input
              placeholder="Départ"
              id="arrivee-input"
              value={arrivee}
              onChange={handleInputChange}
              type="text"
              className="input"
            />
          </div>
          <button type="submit" className="submit-button">Continuer</button>
        </form>
      </div>
      {isDialogOpen && (
        <DialogBox isOpen={isDialogOpen} onClose={handleCloseDialog} />
      )}
     {markerPosition && (
  <div>
    <label className="map-label">
      Ajuster l'emplacement sur la carte pour sélectionner une adresse plus précise.
    </label>
    <Map className="adrmap2"
      google={google}
      zoom={16}
      initialCenter={markerPosition}
      center={markerPosition}
      style={{ width: '600px', height: '600px', marginTop: '-190px', marginLeft: '400px' }}
    >
      <Marker
        position={markerPosition}
        draggable={true} 
        onDragend={(t, map, coord) => handleMarkerDragEnd(t, map, coord)}
      />
    </Map>
  </div>
)}

    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg'
})(Etape2);
