import React, { useState, useEffect } from 'react';
import uploadIcon from './idcard.png';
import fleche from './fleche.png'
import './drivinglicence.css';
const Idcard = () => {
    const [uploadedImage, setUploadedImage] = useState(null);

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {

        window.location.href = '/login';
        return;
      }
  
      const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
      const currentTime = new Date();
  
      if (currentTime >= tokenExpirationDate) {
        // Si le token a expiré, rediriger vers la page de connexion
        window.location.href = '/login';
      }
    }, []);
    
    useEffect(() => {
      const storedImage = localStorage.getItem('pieceidentite');
      if (storedImage) {
        setUploadedImage(storedImage);
      }
    }, []);
  
    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setUploadedImage(reader.result);
          localStorage.setItem('pieceidentite', reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  
    const handleImageClear = () => {
      setUploadedImage(null);
      localStorage.removeItem('pieceidentite');
    };
  
    const handleImageConfirm = () => {
      if (uploadedImage) {
        localStorage.setItem('pieceidentite', uploadedImage);
      }
    };
  
    const handleGoBack = () => {
      window.history.back();
    };
  
  
    return (
        <div>
             <div>
       <img src={fleche} alt="Retour" className="comeback6" onClick={handleGoBack} />
      <p className="drivinglicence-text">Votre pièce d'identité</p>
      {uploadedImage ? (
        <img
          src={uploadedImage}
          alt="Driving Licence"
          className={`icone-drivinglicence${uploadedImage ? ' uploaded' : ''}`}
          style={{
            width: '400px',
            height: '400px',
            border: '2px solid #130C4D',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}

        />
      ) : (
        <div className="upload-icon-container">
          <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
          <label htmlFor="image-upload" className="upload-label">
            Charger la photo de votre pièce d'identité
          </label>
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
      )}

      {uploadedImage && (
        <div className="buttons-container">
          <button className="restart" onClick={handleImageClear}>
            Recommencer
          </button>
          <button className="confirm" onClick={handleImageConfirm}>
            Confirmer
          </button>
        </div>
      )}
    </div>
        </div>
    );
};

export default Idcard;