import React, { useState } from 'react';
import 'react-chat-widget/lib/styles.css';
import './messages.css';

const Messages = () => {
  
    const [message, setMessage] = useState('');
    const [messageList, setMessageList] = useState([]);
    const phoneNumber = '+33752035715';

    const handleSendMessage = () => {
      if (message.trim() !== '') {
          console.log('Message envoyé:', message);
          setMessageList([...messageList, { text: message, user: 'user', timestamp: new Date().getTime() }]); // Store timestamp as a Unix timestamp
          setMessage('');
      }
  };

    return (
      <div className="messagerie-container framed-container">
      <div className="messagerie-header">
          <h1>Messagerie</h1>
          <p>Numéro de téléphone : {phoneNumber}</p>
      </div>
      <div className="messagerie-body">
    {messageList.map((msg, index) => {
        const messageTime = new Date(msg.timestamp);
        const prevMessage = index > 0 ? messageList[index - 1] : null;
        const prevMessageTime = prevMessage ? new Date(prevMessage.timestamp) : null;

        return (
            <div key={index} className={msg.user === 'user' ? 'user-message' : 'response-message'}>
                {(!prevMessageTime || 
                  (messageTime.getDate() !== prevMessageTime.getDate() || 
                   messageTime.getMonth() !== prevMessageTime.getMonth() || 
                   messageTime.getFullYear() !== prevMessageTime.getFullYear())) && (
                    <div className="message-date">
                        {messageTime.toLocaleDateString()}
                    </div>
                )}
                <div className="message-content">
                    <div className="message-text">{msg.text}</div>
                    <div className="message-time">{messageTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                </div>
            </div>
        );
    })}
</div>
      <div className="messagerie-footer">
          <input
              type="text"
              placeholder="Écrivez votre message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={handleSendMessage}>Envoyer</button>
      </div>
      <a
    href={`https://wa.me/${phoneNumber.replace(/\s/g, '')}`}
    target="_blank"
    rel="noopener noreferrer"
>
    Envoyer un message sur WhatsApp
</a>
  </div>
           );
};

export default Messages;