import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { useTransition, animated } from 'react-spring'
import './xcontact.css'
import eyeImage from './eye.png'

import image1 from './image1.svg'
import image2 from './image2.svg'
import image3 from './image3.svg'
import image4 from './image4.svg'

const Xcontact = () => {
	const images = [
		{ id: 1, src: image1, width: '700px', height: '550px' },
		{ id: 2, src: image2, width: '700px', height: '550px' },
		{ id: 3, src: image3, width: '700px', height: '550px' },
		{ id: 4, src: image4, width: '700px', height: '550px' },
	]
	const [currentImageIndex, setCurrentImageIndex] = useState(0)
	const [iterationCount, setIterationCount] = useState(0)
	const [isHovered, setIsHovered] = useState(false)
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const isSuccess = queryParams.get('success') === 'true';

	const [isAccountCreated, setIsAccountCreated] = useState(false);

	useEffect(() => {
		const referrer = document.referrer;
		const isFromServicePage = referrer.endsWith('/service');
	  
		if (isFromServicePage && isSuccess) {
		  console.log('La page a été ouverte depuis /service');
		  setIsAccountCreated(true);
		}
	  }, []);
	  
	useEffect(() => {
		const timer = setTimeout(() => {
			if (iterationCount < 3 && !isHovered) {
				setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
				setIterationCount((count) => count + 1)
			}
		}, 5000)

		return () => clearTimeout(timer)
	}, [currentImageIndex, iterationCount, images.length, isHovered])

	const handleMouseEnter = () => {
		setCurrentImageIndex(0)
		setIterationCount(0)
		setIsHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}

	const transitions = useTransition(currentImageIndex, {
		from: { opacity: 0, position: 'absolute', left: 0, top: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	})

	const [email, setEmail] = useState('')
	const [motDePasse, setMotDePasse] = useState('')
	const [afficherMotDePasse, setAfficherMotDePasse] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [user, setUser] = useState(null)

	const handleEmailChange = (event) => {
		setEmail(event.target.value)
	}

	const handleMotDePasseChange = (event) => {
		setMotDePasse(event.target.value)
	}
	const sendPost = () => {
		return axios
		  .post('https://test-api.bookair.space/api/connexion', {
			email: email,
			password: motDePasse,
		  })
		  .then((response) => {
			console.log(response);
			if (response.data.token) {
			  const userData = response.data;
			  setUser(userData);
			  localStorage.setItem('token', userData.token); 
			  console.log('Adresse e-mail:', userData.email);
			  const redirectUrl = `/homepage?email=${encodeURIComponent(userData.email)}`;
			  window.location.href = redirectUrl;
			  console.log('Bouton "Connecter" cliqué !');
			} else {
			  
			  console.log('La génération du token a échoué');

			}
		  })
		  .catch((error) => {
			if (error.response && error.response.data && error.response.data.errors) {
			  const validationErrors = Object.values(error.response.data.errors).flat();
			  const errorMessage = validationErrors.join(' ');
			  setErrorMessage(errorMessage);
			} else {
			  console.log(error);
			}
		  });
	  };
	  

	const handleToggleAfficherMotDePasse = () => {
		setAfficherMotDePasse(!afficherMotDePasse)
	}

	const loginButtonRef = useRef(null);

	const handleKeyDown = (event) => {
	  if (event.key === 'Enter' && event.target === loginButtonRef.current) {
		sendPost();
	  }
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, []);

	return (
		<div>
			<h1 className="title1">Veuillez vous connecter</h1>
			<div
				className="images-container"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{transitions((style, index) => (
					<animated.img
						key={images[index].id}
						src={images[index].src}
						alt={`Image ${index + 1}`}
						className="animated-image"
						style={{
							...style,
							width: images[index].width,
							height: images[index].height,
						}}
					/>
				))}
			</div>
			{isAccountCreated && (
        <p className="success-message">Votre compte a été créé avec succès, veuillez vous connecter</p>
      )}
			<div className="cover">
				<h1>Je me connecte</h1>
				<input
					type="email"
					placeholder="Email"
					value={email}
					onChange={handleEmailChange}
				/>

				<div className="password-input">
					<input
						type={afficherMotDePasse ? 'text' : 'password'}
						placeholder="Mot de passe"
						value={motDePasse}
						onChange={handleMotDePasseChange}
					/>
					<img
						src={eyeImage}
						alt="Afficher le mot de passe"
						className={`eye-icon ${afficherMotDePasse ? 'afficher' : ''}`}
						onClick={handleToggleAfficherMotDePasse}
					/>
				</div>

				{errorMessage && <p className="error-message">{errorMessage}</p>}
				
				<button className="login-btn" 
				ref={loginButtonRef}
				onClick={sendPost}>
					Connecter
				</button>
				<p className="text">Se connecter en utilisant</p>

				<div className="alt-login">
					<div className="facebook"></div>
					<div className="google"></div>
				</div>
			</div>
		</div>
	)
}

export default Xcontact
