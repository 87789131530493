import React, {useEffect, useState} from 'react'
import './paiement.css';
import visa from './visa.png'
import next from './next.png'
import mastercard from './mastercard.png'

const Paiement = () => {

    const [selectedMode, setSelectedMode] = useState(null);

  const handleModeSelection = (mode) => {
    setSelectedMode(mode);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);

 
    return (
        <div className='paiement'>
             <label htmlFor="paiement" className="label1">
             Sélectionnez votre mode de paiement
          </label>
          <div className="payment-options">
        <button className="carte-bancaire-button" onClick={() => window.location.href = '/cartebancaire'}>
        <span className="button-text">Carte Bancaire</span>
        <div className="card-logos">
          <img src={visa} alt="visa" className="visa" />
          <img src={mastercard} alt="master" className="master" />
        </div>
        <img src={next} alt="next" className="next" />
      </button>
      <hr className="horizontal-line8" />
      <button className="carte-bancaire-button" >
        <span className="button-text">PayBal</span>
        <img src={next} alt="next" className="next1" />
      </button>
      </div>
        </div>
    );
};

export default Paiement;