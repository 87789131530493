import React, { useEffect, useState } from 'react';
import axios from 'axios';
import personne from './2pers.png'
import anime from './anim.png'
import siege from './siege.png'
import prise from './prise.png'
import clim from './clim.png'
import fleche from './fleche.png'
import './etape7.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const Etape7 = () => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [passengerCount, setPassengerCount] = useState(1);
    const [selectedDate, setSelectedDate] = useState(null);
    const [time, setTime] = useState("");
    const [depart, setDepart] = useState('');
    const [arrivee, setArrivee] = useState('');
    const [distance, setDistance] = useState('');
    const [communes, setCommunes] = useState([]);
    const [tarif, setTarif] = useState(0);
    const [prix, setPrix] = useState(0);

    
    const [checkbox1, setCheckbox1] = useState(false);
   	const [checkbox2, setCheckbox2] = useState(false);
	  const [checkbox3, setCheckbox3] = useState(false);
	  const [checkbox4, setCheckbox4] = useState(false);
	  const [checkbox5, setCheckbox5] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
  
    const [userVehicles, setUserVehicles] = useState([]);
 
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adresseDepart = params.get('adressedepart');
    setDepart(adresseDepart);
    const adresseArrivee = params.get('arrivee');
    setArrivee(adresseArrivee);
    const distance = params.get('distance');
    setDistance(distance.toLocaleString('fr-FR', { maximumFractionDigits: 0 }).replace(/[,. ]/g, ''));
    const communes = params.get('communes');

    if (communes) {
      const parsedCommunes = JSON.parse(communes);
      setCommunes(parsedCommunes);
    }

    const date = new Date(params.get('date'));
    setSelectedDate(date);
    const heure = params.get('heure');
    setTime(heure);
    const passengerCount = parseInt(params.get('passengerCount'));
    setPassengerCount(passengerCount);

    const checkbox1 = convertBooleanToInt(params.get('checkbox1') === 'true'); 
    setCheckbox1(checkbox1);
    //console.log(checkbox1)
    
    const checkbox2 = convertBooleanToInt(params.get('checkbox2') === 'true');
    setCheckbox2(convertBooleanToInt(checkbox2));
    //console.log(checkbox2)

    const checkbox3 = convertBooleanToInt(params.get('checkbox3') === 'true');
    setCheckbox3(convertBooleanToInt(checkbox3));
    //console.log(checkbox3)

    const checkbox4 = convertBooleanToInt(params.get('checkbox4') === 'true');
    setCheckbox4(convertBooleanToInt(checkbox4));
    //console.log(checkbox4)

    const checkbox5 = convertBooleanToInt(params.get('checkbox5') === 'true');
    setCheckbox5(convertBooleanToInt(checkbox5));
    //console.log(checkbox5)

  }, []);
  
  const convertBooleanToInt = (value) => {
    return value ? 1 : 0;
  };

  function convertMonthToString(date) {
    const monthNumber = new Date(date).getMonth() + 1;
    const monthNames = [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ];
  
    if (monthNumber >= 1 && monthNumber <= 12) {
      return monthNames[monthNumber - 1];
    } else {
      return 'Mois invalide';
    }
  }
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('https://test-api.bookair.space/api/vehicules', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserVehicles(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user vehicles:', error);
      });
  }, []);

  function extractDay(date){
         
    const day = new Date(date).getDate();
    return day;
  }

  function extractDayOfWeek(date) {
    const dayOfWeekNumber = new Date(date).getDay();
    const dayNames = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    return dayNames[dayOfWeekNumber];
  }

  function extractHour(time) {
    const [hours] = time.split(":");
    return hours;
  }	  
 
  function extractMinutes(time) {
    const [, minutes] = time.split(":");
    return minutes;
  }



  function removePostalCode(address) {
    return address.replace(/\d+/, '').trim();
  }
     
  function extraireVille(adresse) {
    const adresseSplit = adresse.split(',');
    if (adresseSplit.length >= 2) {
      const ville = adresseSplit[adresseSplit.length - 2].trim();
      return ville;
    } else {
      return null;
    }
  }

  function separateDistanceValue(distance) {
    const regex = /^(\d+)/;
    const match = distance.match(regex);
  
    if (match) {
      const value = parseInt(match[1]);
      return value;
    }
  
    return 0;

  }


  const incrementPrix = (prix) => {
    setTarif((prevPrix) => prevPrix + 1); 
    setPrix(prix + 1);
   // console.log(prix);
  };

  const decrementPrix = (prix) => {
    if (prix >= 1) {
      setTarif((prevPrix) => prevPrix - 1);
      //console.log(tarif);
    }
  };
  const prixCalcul = (separateDistanceValue(distance) * 0.08);
 

  useEffect(() => {
    setTarif(prixCalcul);
  }, [prixCalcul]);

  function formatDate(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const formattedDate = formatDate(selectedDate);
 // console.log(formattedDate);

  const handleAjouter =async ()  => {
		const token = localStorage.getItem('token'); 
		
		const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        const decodedPayload = JSON.parse(atob(encodedPayload));
        const userId = decodedPayload.sub;

    
		const data = {
		  depart: depart,
		  arrivee: arrivee,
		  date: formatDate(selectedDate),
		  heure: time,
      tarif: tarif,
      distance: distance,
		  nb_personne: passengerCount,
		  checkbox1: checkbox1,
		  checkbox2: checkbox2,
		  checkbox3: checkbox3,
		  checkbox4: checkbox4,
		  checkbox5: checkbox5,
		  userId: userId,
		};

	
		axios
		  .post('https://test-api.bookair.space/api/ajoutrajet', data, {
			headers: {
			  Authorization: `Bearer ${token}` 
			}

		  })
		  .then(response => {
			setSuccessMessage('Trajet ajouté avec succès !');
      setDialogOpen(true);
			
			setErrorMessage('');
		  })
		  .catch(error => {
			if (error.response && error.response.data && error.response.data.message) {
                console.error(error.response.data.message);
                setErrorMessage(error.response.data.message);
            } else {
                console.error('Une erreur s\'est produite lors de la requête.');
            }
		  });
	  };
	

    const handleGoBack = () => {
      window.history.back();
    };
  
    function arrondirPrix(prix) {
      const entier = Math.floor(prix); 
      const decimale = prix - entier; 
    
      
      const prixArrondi = decimale < 0.5 ? entier : Math.ceil(prix);
    
      return prixArrondi;
    }

    const handleButtonClick1=() => {
      setDialogOpen(false);
      window.location.href = `/tripslist`;
    };

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }
  
      const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
      const currentTime = new Date();
  
      if (currentTime >= tokenExpirationDate) {
        window.location.href = '/login';
      }
    }, []);

    
  return (
    <div>
       <img src={fleche} alt="Retour" className="comeback6" onClick={handleGoBack} />
       <div className='step7-date'>
            
			<p> { extractDayOfWeek(selectedDate)} { extractDay(selectedDate)} {convertMonthToString(selectedDate)}</p>
			
        </div>

		<hr className="horizontal-line" />

		<div className="depart-arrivee1">
  <p className='from' >{extraireVille(removePostalCode(depart))}</p>
  <div className="dot-container1">
    <span className="dot">•</span>
    <span className="dot">•</span>
  </div>
  <p className='distance' >{distance}</p>
  <div className="dot-container1">
    <span className="dot">•</span>
    <span className="dot">•</span>
  </div>
  <p className='to' >{extraireVille(removePostalCode(arrivee))}</p>
  <p className='heuredepart1'>
  <span className='departure-label'>Départ :</span>
  <span className='departure-time'>{extractHour(time)}:{extractMinutes(time)}</span>
  </p>
  <p className='step7-deparv'>
  <span className='step7-label1'>De :</span>
  <span className='step7-adr1'>{depart}</span>
</p>

<p className='adrarv1'>
  <span className='step7-label2'>À :</span>
  <span className='step7-adr2'>{arrivee}</span>
</p>
  
<p className='escals'>
  <span className='escals-label'>En passant par :</span>
  <span className='affichage-escals'>{Object.values(communes).map((commune, index) => (
          <span key={index}>{commune}, &nbsp;</span>
        ))}</span>
</p>

<p className='nb_passager1'>
  <span className='nbpassager-label'>Nombre de passager :</span>
  <span className='nbpassager-adr'>{passengerCount}</span>
</p>

  <hr className="horizontal-linee2" />
  <p className='prixx1'> Prix total pour un passager : </p>
  <p className='prxx1'> <button onClick={() => decrementPrix(tarif)} className="counter-button">
        -
      </button>
      <span className="counter-value">{arrondirPrix(tarif)}</span>
      <button onClick={() => incrementPrix(tarif)} className="counter-button">
        +
      </button></p>
  <hr className="horizontal-line3" />

  <p className='service1'> Services et équipements</p>
  <div className="servicees">

          {checkbox1 === 1 && <img src={personne} alt="icone1" className="servicee1" />}
          {checkbox2 === 1 && <img src={anime} alt="icone2" className="servicee1" />}
          {checkbox3 === 1 && <img src={clim} alt="icone3" className="servicee1" />}
          {checkbox4 === 1 && <img src={prise} alt="icone4" className="servicee1" />}
          {checkbox5 === 1 && <img src={siege} alt="icone5" className="servicee1" />}
        </div>

  <hr className="horizontal-linee4" />
	<div className="continuer"  onClick={handleAjouter}>
					Confirmer
				</div>
        {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
        {successMessage && <span style={{ color: 'green' }}>{successMessage}</span>}
       
</div>
{dialogOpen && (
      <div className="custom-dialog">
        <div className="dialog-content">
          <CheckCircleIcon style={{ color: 'green', fontSize: 48 }} />
          <Typography variant="h6">Trajet ajouté avec succès !</Typography>
          <Button onClick={handleButtonClick1}>OK</Button>
        </div>
      </div>
    )}

    </div>
  );
};

export default Etape7;
