import React, { useEffect, useState } from 'react';
import './colis.css';
import fleche from './fleche.png'
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const Colis = () => {
    
    const [selectedDate, setSelectedDate] = useState(null);
    const [time, setTime] = useState("");
    const [depart, setDepart] = useState('');
    const [arrivee, setArrivee] = useState('');
    const [distance, setDistance] = useState('');
    const [tailleColis, setTailleColis] = useState('s');
    const [hauteur, setHauteur] = useState('');
    const [largeur, setLargeur] = useState('');
    const [longueur, setLongueur] = useState('');
    const [poidsMax, setPoidsMax] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [tarif, setTarif] = useState(0);
    const [annonce, setAnnonce] = useState(0);

    const [prix, setPrix] = useState(0);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const adresseDepart = params.get('adressedepart');
        setDepart(adresseDepart);
        const adresseArrivee = params.get('arrivee');
        setArrivee(adresseArrivee);
        console.log("affichage de la distance ", distance.toLocaleString('fr-FR', { maximumFractionDigits: 0 }).replace(/[,. ]/g, ''));
        const date = new Date(params.get('date'));
        setSelectedDate(date);
        const annonce = params.get('annonce');
      
        const annonceValue = annonce === null || annonce.trim() === '' ? 0 : parseInt(annonce, 10) || 0;
        setAnnonce(annonceValue);
       
        const heure = params.get('heure');
        setTime(heure);

     
      }, []);

  
      function formatDate(inputDateString) {
        const date = new Date(inputDateString);
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
      }

      const handleGoBack = () => {
        window.history.back();
      };

      const calculerMontantTailleColis = (taille) => {
        switch (taille) {
          case 'xs':
            return 15; // Montant pour la taille "xs"
          case 's':
            return 25; // Montant pour la taille "s"
          case 'm':
            return 35; // Montant pour la taille "m"
          case 'l':
            return 45; // Montant pour la taille "l"
          case 'xl':
            return 50; // Montant pour la taille "xl"

          default:
            return 0; // Montant par défaut
        }
      }

    
      useEffect(() => {
        // Appeler votre fonction pour calculer le tarif en fonction de la nouvelle taille
        const nouveauTarif = calculerMontantTailleColis(tailleColis);
      
        // Mettre à jour l'état du tarif avec la nouvelle valeur calculée
        setTarif(nouveauTarif);
      }, [tailleColis]);
      

      const handleAjouterColis =  (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
    
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        const decodedPayload = JSON.parse(atob(encodedPayload));
        const userId = decodedPayload.sub;
    
        if (!depart || !arrivee || !selectedDate || !time || !poidsMax) {
          setErrorMessage('Veuillez remplir tous les champs obligatoires.');
          return;
      }

    
        const colisData = {
            depart: depart,
            arrivee: arrivee,
            date: selectedDate.toISOString().split('T')[0],
            heure: time,
            taille: tailleColis,
            poids_max: poidsMax,
            tarif: tarif,
            annonce: annonce,
            user_id: userId,
        };
    
        axios
            .post('https://test-api.bookair.space/api/ajouter-colis', colisData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setSuccessMessage('Colis ajouté avec succès !');
                setDialogOpen(true);
    
                setErrorMessage('');
            })
            .catch(error => {
              if (error.response && error.response.data && error.response.data.message) {
                if (error.response.status === 409) {
                    setErrorMessage('Ce colis existe déjà.');
                } else {
                    console.error(error.response.data.message);
                    setErrorMessage(error.response.data.message);
                }
            } else {
                console.error('Une erreur s\'est produite lors de la requête.');
            }
            });
    };
    
    const handleButtonClick1=() => {
      setDialogOpen(false);
      if(annonce===1){
        window.location.href = `/annonce`;
      }
      else{
        window.location.href = `/tripslist`;
      }
      
    };

    function separateDistanceValue(distance) {
      const regex = /^(\d+)/;
      const match = distance.match(regex);
    
      if (match) {
        const value = parseInt(match[1]);
        return value;
      }
    
      return 0;
  
    }
    function arrondirPrix(prix) {
      const entier = Math.floor(prix); 
      const decimale = prix - entier; 
    
      
      const prixArrondi = decimale < 0.5 ? entier : Math.ceil(prix);
    
      return prixArrondi;
    }

    const incrementPrix = (prix) => {
      setTarif((prevPrix) => prevPrix + 1); 
      setPrix(prix + 1);
     // console.log(prix);
    };
  
    const decrementPrix = (prix) => {
      if (prix >= 1) {
        setTarif((prevPrix) => prevPrix - 1);
        //console.log(tarif);
      }
    };

    /*const prixCalcul = (separateDistanceValue(distance.toLocaleString('fr-FR', { maximumFractionDigits: 0 })) * 0.08);
    useEffect(() => {
      setTarif(prixCalcul);
    }, [prixCalcul]);*/

    return (
        <div className='colis-container'>
           <img src={fleche} alt="Retour" className="comeback5" onClick={handleGoBack} />
        <h1 className='colis-texte'>Créer un trajet de livraison de colis</h1>
        <form className='forms'>
          <label className='label-colis1'>
            Adresse de départ:
          </label>
            <input className='inputs' type="text" value={depart} onChange={(e) => setDepart(e.target.value)} required />
         
          <label className='label-colis1'>
            Adresse d'arrivée:
            </label>
            <input className='inputs' type="text" value={arrivee} onChange={(e) => setArrivee(e.target.value)} required />
          
          <label className='label-colis1'>
            Date:
            </label>

            <input className='inputs' type="text" value={formatDate(selectedDate)} required />
          
          <label className='label-colis1'>
            Heure:
            </label>

            <input className='inputs' type="time" value={time} required />
          
          <label className='label-colis1'>
            Taille du colis:
            </label>
            <select className='selector' value={tailleColis} onChange={(e) => setTailleColis(e.target.value)} required>
            <option value="s">XS(200 x 140 x 50)</option>
              <option value="s">S(330 x 210 x 80)</option>
              <option value="m">M(230 x 130 x 120)</option>
              <option value="l">L(290 x 210 x 150)</option>
              <option value="xl">XL(400 x 275 x 195)</option>
            </select>
          
          <label className='label-colis1'>
            Poids maximum (en kg):
            </label>
            <input className='inputs' type="number" value={poidsMax} onChange={(e) => setPoidsMax(e.target.value)} required />
            <div className="vertical-align-container"> 
           <label className='label-colis2'> Prix total: </label>

               <p className='prxx1'> <button  type='button' onClick={() => decrementPrix(tarif)} className="counter-button">
        -
      </button>
      <span className="counter-value">{arrondirPrix(tarif)}</span>
      <button onClick={() => incrementPrix(tarif)} className="counter-button">
        +
      </button></p> 
      </div>
          <button type='button' className='validate-colis' onClick={handleAjouterColis} >Créer le trajet</button>
          
        </form>
        {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
        {successMessage && <span style={{ color: 'green' }}>{successMessage}</span>}
        {dialogOpen && (
      <div className="custom-dialog">
        <div className="dialog-content">
          <CheckCircleIcon style={{ color: 'green', fontSize: 48 }} />
          <Typography variant="h6">Trajet ajouté avec succès !</Typography>
          <Button onClick={handleButtonClick1}>OK</Button>
        </div>
      </div>
    )}
      </div>
    );
};

export default Colis;