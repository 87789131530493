import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper} from 'google-maps-react';
import axios from 'axios';
import profil  from '../Public/iconprofil.png'
import personne from '../private/2pers.png'
import anime from '../private/anim.png'
import siege from '../private/siege.png'
import prise from '../private/prise.png'
import clim from '../private/clim.png'
import imageDuColis from '../private/colis.png'
import passenger from '../Public/passager.png'
import result from '../Public/carte.png'
import './listetrajets.css'

const ListeTrajets = () => {

    const [trajets, setTrajets] = useState([]);
    const [colis, setColis] = useState([]);
    const [resultats, setResultats] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [selectedTrajet, setSelectedTrajet] = useState(null);
    const [visibleTrajets, setVisibleTrajets] = useState([]);
    
    useEffect(() => {
        const fetchTrajets = async () => {
          try {
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
    
            const response = await axios.get('https://test-api.bookair.space/api/mestrajets', { headers });
            const trajets = response.data.filter((trajet) => {
              const trajetDate = new Date(trajet.date);
              const currentDate = new Date();
              return trajetDate >= currentDate;
            });
    
            setTrajets(trajets);
            setResultats(trajets);
            console.log(trajets);
         
           
          } catch (error) {
            console.error('Erreur lors de la récupération des trajets :', error);
          }
        };
    
        fetchTrajets();
      }, []);

      useEffect(() => {
        const fetchColis = async () => {
            try {
                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
    
                const responseColis = await axios.get('https://test-api.bookair.space/api/mescolis', { headers });
                const colisAvecAnnonceZero = responseColis.data.filter(colis => colis.annonce === 0);
            console.log("les colis avec annonce égale à 0", colisAvecAnnonceZero);
            setColis(colisAvecAnnonceZero);
                console.log(colis)
            } catch (error) {
                console.error('Erreur lors de la récupération des colis :', error);
            }
        };
    
        fetchColis();
    }, []);


    useEffect(() => {
      setResultats([...trajets, ...colis].sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
      }));
  }, [trajets, colis]);

  
      function removePostalCode(address) {
        return address.replace(/\d+/, '').trim();
      }

      const handleClick = (id) => {
        const trajetId= id;
        const redirectUrl = `/tripdetails3?id=${encodeURIComponent(trajetId)}`;
        window.location.href = redirectUrl;
    };
      const isResultFound = resultats.length > 0; 
  
      let dateToShow = null;
    
      if (isResultFound) {
        dateToShow = (
          <div className='date-search'>
          
          </div>
        );
      }
      function extractCity(adresse) {
        const adresseSplit = adresse.split(',');
        if (adresseSplit.length >= 2) {
          const ville = adresseSplit[adresseSplit.length - 2].trim();
          return ville;
        } else {
          return null;
        }
      }

      useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          return;
        }
    
        const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
        const currentTime = new Date();
    
        if (currentTime >= tokenExpirationDate) {
          window.location.href = '/login';
        }
      }, []);

      
    return (
        <div  className='resultat1'>
          <div>
            <label className="mytrip-list">
           Mes trajets:
         </label>
         </div>

         <div className="result-container">

{isResultFound ? (
      
        <>
          {dateToShow}

          {resultats.map(trajet => (
                            <div onClick={() => handleClick(trajet.id)} style={{ height: '200px', width: '560px' }} 
                            className="triangle-ListTripsContainer" key={trajet.id}>
                            <div className="triangle-listtrips">
                                <p className="departureArrival">
                                    {removePostalCode(extractCity(trajet.depart))}
                                    <br />
                                    {removePostalCode(extractCity(trajet.arrivee))}
                                </p>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '270px' }}>
                                    {trajet.nb_personne !== undefined ? (
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={passenger} alt="passager" className="passengerIcon" style={{ height: '28px', width: '28px' }} />
                                              <div>
              
                                                <p className='text_liste'>Trajet de covoiturage :</p>
                                                </div>
                                                 <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                   <p className='texte_passager' style={{ color: '#130C4D', marginRight: '5px' }}>{trajet.nb_personne} passager</p>
                                                   
                                                 </div>
                                                 </div>
                                        </>
                                    ) : (
                                         <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={imageDuColis} alt="colis" className="colisIcon" style={{ marginLeft: '10px' }} />
                                        <p className='text_liste'>Trajet livraison de colis </p>
                                        </div>
                                    )}
                                </div>
                                <p className="price-search">{Math.round(trajet.tarif)}€</p>
                                <div className="driver">
                                    <p className="driver-info">
                                        {userInfo[trajet.user_id]?.name} {userInfo[trajet.user_id]?.firstName}
                                    </p>
                                </div>
        <div className="services-triplist">
          {trajet.checkbox1 === 1 && <img src={personne} alt="icone1" className="iconetwo" />}
          {trajet.checkbox2 === 1 && <img src={anime} alt="icone2" className="service-search1" />}
          {trajet.checkbox3 === 1 && <img src={clim} alt="icone3" className="service-search1" />}
          {trajet.checkbox4 === 1 && <img src={prise} alt="icone4" className="service-search1" />}
          {trajet.checkbox5 === 1 && <img src={siege} alt="icone5" className="service-search1" />}
        </div>
      </div>
    </div>    
  ))}
        </>
      ) : (
  
        <div className='noresult-container'>
          <p className='noresulttext'>Aucun résultat trouvé.</p>
          <img src={result} alt="noresult" className="noresult" />
        </div>
      )}

    </div>
          </div>
    );
};

export default ListeTrajets;

