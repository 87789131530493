import React, {useEffect, useState} from 'react'
import axios from 'axios';
import personne from './2pers.png'
import anime from './anim.png'
import siege from './siege.png'
import prise from './prise.png'
import clim from './clim.png'
import fleche from './fleche.png'
import car from '../Public/car.png'

import './tripdetails.css'

const TripDetails = () => {


	const [selectedTrajet, setSelectedTrajet] = useState(null);
	const [trajet_id, setTrajet] = useState(null);
    const [depart, setDepart] = useState('');
	const [arrivee, setArrivee] = useState('');
    const [date, setDate] = useState('');
	const [heure, setHeure] = useState('');
	
    const [nb_personne, setNbPersonne] = useState('');
	const [tarif, setTarif] = useState('');
	const [user_id, setUserid] = useState('');
	const [userName, setUserName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [distance, setDistance] = useState('');
	const [showSuccessDialog, setShowSuccessDialog] = useState(false);

	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(false);
	const [checkbox4, setCheckbox4] = useState(false);
	const [checkbox5, setCheckbox5] = useState(false);
   
	const [colis, setColis] = useState([]);
	const [taille, setTaille] = useState('');
	const [poid_max, setPoidmax] = useState('');


    var urlParams = new URLSearchParams(window.location.search);
    var id = urlParams.get('id');

	console.log("the id of my tirp", id);
	function convertMonthToString(date) {
		const monthNumber = new Date(date).getMonth() + 1;
		const monthNames = [
		  'janvier',
		  'février',
		  'mars',
		  'avril',
		  'mai',
		  'juin',
		  'juillet',
		  'août',
		  'septembre',
		  'octobre',
		  'novembre',
		  'décembre',
		];
	  
		if (monthNumber >= 1 && monthNumber <= 12) {
		  return monthNames[monthNumber - 1];
		} else {
		  return 'Mois invalide';
		}
	  }

	  function extractDay(date){
             
		const day = new Date(date).getDate();
		return day;
	  }

	  function extractDayOfWeek(date) {
		const dayOfWeekNumber = new Date(date).getDay();
		const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'samedi'];
        return dayNames[dayOfWeekNumber];
	  }
	  


    useEffect(() => {
		const fetchTripDetails = async () => {
			
		  try {
			
			const response = await axios.get(`https://test-api.bookair.space/api/trajetdetails/${id}`);
			const { depart, arrivee, date, heure, tarif, distance, user_id, nb_personne, checkbox1, checkbox2, checkbox3, checkbox4, checkbox5} = response.data;

			setDepart(depart);
			setArrivee(arrivee);
			setDate(date);
			setHeure(heure);
			setTarif(tarif);
            setDistance(distance);
			setUserid(user_id);
			setNbPersonne(nb_personne);
			setCheckbox1(checkbox1);
			setCheckbox2(checkbox2);
			setCheckbox3(checkbox3);
			setCheckbox4(checkbox4);
			setCheckbox5(checkbox5);


			const responseUser = await axios.get(`https://test-api.bookair.space/api/userdetails/${user_id}`);
			
			const { user } = responseUser.data;
			const { name, firstName } = user;
		
			setUserName(name);
			setUserLastName(firstName);

         
		   
		  } catch (error) {
			console.error('Erreur lors de la récupération des détails du trajet :', error);
		  }
	
		};
	    
		fetchTripDetails();
	  }, [id]);

      const test= user_id;
	  console.log("test_id", test);
	   
	  function extractHour(time) {
		const [hours] = time.split(":");
		return hours;
	  }	  
     
	  function extractMinutes(time) {
		const [, minutes] = time.split(":");
		return minutes;
	  }
	
    function removePostalCode(address) {
      return address.replace(/\d+/, '').trim();
    }

	  function extractHour(time) {
		const [hours] = time.split(":");
		return hours;
	  }	  
     
	  function extractMinutes(time) {
		const [, minutes] = time.split(":");
		return minutes;
	  }
	
    function removePostalCode(address) {
      return address.replace(/\d+/, '').trim();
    }

    function extraireVille(adresse) {
      const adresseSplit = adresse.split(',');
      if (adresseSplit.length >= 2) {
        const ville = adresseSplit[adresseSplit.length - 2].trim();
        return ville;
      } else {
        return null;
      }
    }

	const handleGoBack = () => {
		window.history.back();
	  };

	function removeCountryFromAddress(address) {
		const addressArray = address.split(','); 
		if (addressArray.length > 1) {
		  addressArray.pop();
		  return addressArray.join(',').trim(); 
		} else {
		  return '';
		}
	  }
	

	 const handleEditClick = () => {
		
		const mode = colis.length > 0 ? 1 : 0;

			let editLink = '';
		
		console.log(mode);
			if (colis.length > 0) {
				// For package delivery trip
				editLink = `/editpackage?id=${id}&date=${date}&heure=${heure}&depart=${encodeURIComponent(depart)}&arrivee=${encodeURIComponent(arrivee)}&mode=${encodeURIComponent(mode)}&tarif=${tarif}&taille=${taille}&poid_max=${poid_max}`;
			} else {
				// For carpooling trip
				editLink = `/editrip?id=${id}&date=${date}&heure=${heure}&depart=${encodeURIComponent(depart)}&arrivee=${encodeURIComponent(arrivee)}&mode=${encodeURIComponent(mode)}`;
			}
		
			window.location.href = editLink;
		
		
		/*const editTripLink = `/editrip?id=${id}&date=${date}&heure=${heure}&depart=${encodeURIComponent(depart)}&arrivee=${encodeURIComponent(arrivee)}`;
		window.location.href = editTripLink;*/
	

    };

	const handleSuppression = (id) => {
		setSelectedTrajet(id);
		console.log("the id", id);
	  };


	  const handleConfirmation = async (trajetId) => {

		console.log("la taille", taille);
		console.log("le poid", poid_max);
		if (taille !='' && poid_max !='') {
			// C'est un trajet colis, effectuez la suppression de la table "colis"
			try {
			  const token = localStorage.getItem('token');
			  const headers = {
				Authorization: `Bearer ${token}`,
			  };
			
			  await axios.delete(`https://test-api.bookair.space/api/suppressionColis/${id}`, { headers });
			 
			  console.log('Trajet colis supprimé avec succès');
			  setSelectedTrajet(null)
			  setShowSuccessDialog(true);
			} catch (error) {
			  console.error('Erreur lors de la suppression du trajet colis :', error);
			}
		}

	    
		try {
		  const token = localStorage.getItem('token');
		  const headers = {
			Authorization: `Bearer ${token}`,
		  };
	
		  await axios.delete(`https://test-api.bookair.space/api/suppressionTrajet/${trajetId}`, { headers });
		 
		  console.log('Trajet supprimé avec succès');
		  setSelectedTrajet(null)
		  setShowSuccessDialog(true);

		} catch (error) {
		  console.error('Erreur lors de la suppression du trajet :', error);
		} finally {
		 
		
	}
	  };

	  const handleButtonClick = () => {
		setShowSuccessDialog(false);
		window.location.href = `/tripslist`;
		
	  };

	  useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          return;
        }
    
        const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
        const currentTime = new Date();
    
        if (currentTime >= tokenExpirationDate) {
          window.location.href = '/login';
        }
      }, []);


	  useEffect(() => {
		const fetchColis = async () => {
		  const urlParams = new URLSearchParams(window.location.search);
		  const tripId = urlParams.get('id');
	  
		  const params = {
			id: tripId,
		  };
	  
		  try {
			const responseColis = await axios.get('https://test-api.bookair.space/api/affichageColis', { params });
			const colis = responseColis.data;
	  
			const userDetailPromises = colis.map(async (coli) => {
			  const depart = coli.depart;
			  setDepart(depart);
			  const arrivee = coli.arrivee;
			  setArrivee(arrivee);
			  const date = coli.date;
			  setDate(date);
			  const heure = coli.heure;
			  setHeure(heure);
			  const taille = coli.taille;
			  setTaille(taille);
			  const poid_max = coli.poids_max;
			  setPoidmax(poid_max);
			  const tarif = coli.tarif;
			  setTarif(tarif);
			  const user_id = coli.user_id;
			  setUserid(user_id);
	  
			  console.log('Départ:', depart);
			  console.log('Arrivée:', arrivee);
			  console.log('Date:', date);
			  console.log('heure:', heure);
			  console.log('taille:', taille);
			  console.log("poid max", poid_max);
			  console.log('tarif:', tarif);
			  console.log("user id", user_id);
	  
			  // Effectuer la requête userdetails pour chaque utilisateur
			  const responseUser = await axios.get(`https://test-api.bookair.space/api/userdetails/${user_id}`);
			  const { user } = responseUser.data;
			  const { name, firstName } = user;
			  console.log("the id of the user",user_id);
			  console.log("the name and the firstname",name, firstName);
			  setUserName(name);
			  setUserLastName(firstName);
			});
	  
			// Attendre que toutes les requêtes userdetails se terminent
			await Promise.all(userDetailPromises);
	  
			setColis(colis);
		  } catch (error) {
			console.error('Erreur lors de la récupération des colis :', error);
		  }
		};
	  
		fetchColis();
	  }, []);
	  
	  
    return (
        <div>
			<img src={fleche} alt="Retour" className="comeback6" onClick={handleGoBack} />
            <div className='date2'>
					
					<p> { extractDayOfWeek(date)} { extractDay(date)} {convertMonthToString(date)}</p>
					
				</div>
		
				<hr className="trip-horizontal-line1" />
		
				<div className="deparv-container">
		  <p>{extraireVille(removePostalCode(depart))}</p>
		  <div className="dot-container">
			<span className="dot">•</span>
			<span className="dot">•</span>
		  </div>
		  <p className='detailstrajet-distance' >{distance}</p>
		  <div className="dot-container">
			<span className="dot">•</span>
			<span className="dot">•</span>
		  </div>
		  <p>{extraireVille(removePostalCode(arrivee))}</p>
<div className='bloc1'>
 <div className="row">
  <div className="adrhr2">
    <span className='departure-label3'>Départ :</span>
    <span className='departure-time2'>{extractHour(heure)}:{extractMinutes(heure)}</span>
  </div>
</div>

<div className="row">
  <div className='adrde2'>
    <span className='dtadr-label3'>De :</span>
    <span className='adrdep-adr2'>{removeCountryFromAddress(removePostalCode(depart))}</span>
  </div>
</div>

<div className="row">
  <div className='adrarv2'>
    <span className='dtadr3-label3'>À :</span>
    <span className='adrarv-adr2'>{removeCountryFromAddress(removePostalCode(arrivee))}</span>
  </div>
</div>
</div>
		
{colis.length > 0 && (
  <div className='taille_poids'>
    <p>Taille du colis : {taille}</p>
    <p>Poids maximal : {poid_max} kg</p>
  </div>
)}
		<div className="services-detailstrajet">
		
		{checkbox1 === 1 && <img src={personne} alt="icone1" className="service-detailstrajet" />}
		{checkbox2 === 1 && <img src={anime} alt="icone2" className="service-detailstrajet" />}
		{checkbox3 === 1 && <img src={clim} alt="icone3" className="service-detailstrajet" />}
		{checkbox4 === 1 && <img src={prise} alt="icone4" className="service-detailstrajet" />}
		{checkbox5 === 1 && <img src={siege} alt="icone5" className="service-detailstrajet" />}
		</div>
		
		
		  <hr className="trip-horizontal-line2" />
		  <p className='price'> Prix total pour un passager</p>
		  <p className='price1'> {Math.round(tarif)}€</p>
			<hr className="horizontal-line5" />
		  <img src={car} alt="iconprof" className="iconcar2"/>
		  <hr className="horizontal-line5" />
			<div className="edit customColor"onClick={handleEditClick} >
							Modifier mon annonce 
			</div>
            <hr className="trip-horizontal-line3" />
			<div className="delete customColor" onClick={() => handleSuppression(id)} >
							Annuler le trajet 
			</div>
			<hr className="trip-horizontal-line3" />
		</div>
		{selectedTrajet && (
        <div className="modal">
          <div className="modal-content">
            <p>Êtes-vous sûr de vouloir supprimer ce trajet ?</p>
            <button className="butt1" onClick={() => handleConfirmation(selectedTrajet)}>
              Je confirme
            </button>
            <button className="butt2" onClick={() => setSelectedTrajet(null)}>
              Annuler
            </button>
          </div>
        </div>
      )}
     {showSuccessDialog && (
        <div className="modal">
          <div className="modal-content">
            <p>Trajet supprimé avec succès</p>
            <button className="butt1" onClick={handleButtonClick}>
              OK
            </button>
          </div>
        </div>
      )}

        </div>
    );
};

export default TripDetails;