import React, { useState, useEffect} from 'react';
import { GoogleApiWrapper} from 'google-maps-react';
import heure from './court.png';
import plustot from './heure.png';
import moinscher from './moinscher.png';
import personne from './2pers.png'
import anime from './anim.png'
import clim from './clim.png'
import prise from './prise.png'
import siege from './siege.png'
import profil from './profil.png';
import passenger from '../Public/passager.png'
import result from '../Public/carte.png'
import imageDuColis from '../private/colis.png'

import './recherchefromaccueil.css';
import axios from 'axios';

const Recherche = ({ google }) => {

  const [trajets, setTrajets] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [resultats, setResultats] = useState([]);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [maxTwoPersonsChecked, setMaxTwoPersonsChecked] = useState(false);
  const [animauxChecked, setAnimauxChecked] = useState(false);
  const [climatisationChecked, setClimatisationChecked]= useState(false);
  const [priseChecked, setPriseChecked]= useState(false);
  const [siegeChecked, setSiegeChecked]= useState(false);
  const [colis, setColis] = useState([]);

  useEffect(() => {
		// Initialisation de l'autocomplétion pour le champ de départ
		const departAutocomplete = new google.maps.places.Autocomplete(
		  document.getElementById('depart-input')
		);
	
		departAutocomplete.addListener('place_changed', () => {
		  const place = departAutocomplete.getPlace();
		  setDepart(place.formatted_address);
		});
	
		// Initialisation de l'autocomplétion pour le champ d'arrivée
		const arriveeAutocomplete = new google.maps.places.Autocomplete(
		  document.getElementById('arrivee-input')
		);
	
		arriveeAutocomplete.addListener('place_changed', () => {
		  const place = arriveeAutocomplete.getPlace();
		  setArrivee(place.formatted_address);
		});
	  }, [google]);
    const calculateDistance = (address1, address2, google) => {
      const directionsService = new google.maps.DirectionsService();
  
      return new Promise((resolve, reject) => {
        directionsService.route(
          {
            origin: address1,
            destination: address2,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              const distance = result.routes[0].legs[0].distance.value; // Distance in meters
              const distanceInKm = distance / 1000; // Convert distance to kilometers
              resolve(distanceInKm);
              console.log("la distance entre adr1 et adr2",address1, address2, distanceInKm);
            } else {
              reject(new Error('Unable to calculate distance.'));
            }
          }
        );
      });
    };
  
 
    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const depart = searchParams.get('depart');
      const arrivee = searchParams.get('arrivee');
      const datee = searchParams.get('date');
      const nbPersonne = searchParams.get('nbPersonne');
    
      setDepart(depart);
      setArrivee(arrivee);
      setDate(datee);
      setNbPersonne(nbPersonne);
    
      const handleRecherche = async () => {
        const params = {
          date: datee,
          nbPersonne: nbPersonne,
        };
    
        try {
          const response = await axios.get('https://test-api.bookair.space/api/rechercherTrajets', { params });
          const trajets = response.data;
          const filteredTrajets = [];
    
          for (const trajet of trajets) {
            const distanceDepart = await calculateDistance(depart, trajet.depart, google);
            const distanceArrivee = await calculateDistance(arrivee, trajet.arrivee, google);
            if (distanceDepart < 60&& distanceArrivee < 60 ) {
              filteredTrajets.push(trajet);
            }
          }
    
          setTrajets(filteredTrajets);
          setResultats(filteredTrajets);
    
          const userIds = filteredTrajets.map((trajet) => trajet.user_id);
          fetchUserDetails(userIds);
        } catch (error) {
          console.error('Une erreur s\'est produite lors de la requête de recherche.');
        }
      };
    
      const fetchUserDetails = userIds => {
        axios
          .get('https://test-api.bookair.space/api/getUserDetails', { params: { userIds } })
          .then(response => {
            const userDetails = response.data;
            setUserInfo(userDetails);
          })
          .catch(error => {
            console.error('Une erreur s\'est produite lors de la récupération des détails de l\'utilisateur.');
          });
      };
    
      handleRecherche();
    }, []);
    

  const [depart, setDepart] = useState('');
  const [arrivee, setArrivee] = useState('');
  const [date, setDate] = useState('');
  const [nbPersonne, setNbPersonne] = useState('');
  

  const fetchUserDetails = userIds => {
    axios
      .get('https://test-api.bookair.space/api/getUserDetails', { params: { userIds } })
      .then(response => {
        const userDetails = response.data;
        setUserInfo(userDetails);
        
      })
      .catch(error => {
        console.error('Une erreur s\'est produite lors de la récupération des détails de l\'utilisateur.');
      });
  };


  const handleRecherche = async () => {
    const params = {
      arrivee: arrivee,
      date: date,
      nbPersonne: nbPersonne,
    };
  
    console.log(params);
  
    try {
      const response = await axios.get('https://test-api.bookair.space/api/rechercherTrajets', { params });
      const trajets = response.data;
      const filteredTrajets = [];
    
      for (const trajet of trajets) {
        const distanceDepart = await calculateDistance(depart, trajet.depart, google);
        const distanceArrivee = await calculateDistance(arrivee, trajet.arrivee, google);
        if (distanceDepart < 60&& distanceArrivee < 60 ) {
          filteredTrajets.push(trajet);
        }
      }
    
      setTrajets(filteredTrajets);
      setResultats(filteredTrajets);
    
      const userIds = filteredTrajets.map((trajet) => trajet.user_id);
      fetchUserDetails(userIds);
    } catch (error) {
      console.error('Une erreur s\'est produite lors de la requête de recherche.');
    }

    try {
      const responseColis = await axios.get('https://test-api.bookair.space/api/rechercherColis', { params });
      const colis = responseColis.data;
      
      console.log(depart, arrivee, date)
      console.log("les trajets colis", colis)
      const filteredColis = []; 

      for (const coli of colis) {
        const distanceDepart = await calculateDistance(depart, coli.depart, google);
        const distanceArrivee = await calculateDistance(arrivee, coli.arrivee, google);
        if (distanceDepart < 60&& distanceArrivee < 60 ) {
          filteredColis.push(coli);
        }
      }

      setColis(filteredColis);
      setResultats(filteredColis);

      const userIds = filteredColis.map((colis) => colis.user_id);
      console.log("iduser",userIds);

      fetchUserDetails(userIds);
  }
   catch (error) {
      console.error('Erreur lors de la récupération des colis :', error);
  }

  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const depart = searchParams.get('depart');
    const arrivee = searchParams.get('arrivee');
    const datee = searchParams.get('date');
    
  
    setDepart(depart);
    setArrivee(arrivee);
    setDate(datee);

    
   
    const fetchColis = async () => {
      const params = {

        date: datee,
      };
     
        try {
            const responseColis = await axios.get('https://test-api.bookair.space/api/rechercherColis', { params });
            const colis = responseColis.data;
            
            console.log("les trajets colis", colis)
            console.log("je cherche l'id du colis",colis.id);
            const filteredColis = []; 

            for (const coli of colis) {
              console.log("ID du colis :", coli.id);

              const distanceDepart = await calculateDistance(depart, coli.depart, google);
              const distanceArrivee = await calculateDistance(arrivee, coli.arrivee, google);
              if (distanceDepart < 60&& distanceArrivee < 60 ) {
                filteredColis.push(coli);
              }
            }

            setColis(filteredColis);
            setResultats(filteredColis);
            const userIds = filteredColis.map((colis) => colis.user_id);
            console.log("iduser",userIds);
            

          fetchUserDetails(userIds);
            
        }

         catch (error) {
            console.error('Erreur lors de la récupération des colis :', error);
        }

        
    };

    fetchColis();
    
    const fetchUserDetails = userIds => {
      axios
        .get('https://test-api.bookair.space/api/getUserDetails', { params: { userIds } })
        .then(response => {
          const userDetails = response.data;
          setUserInfo(userDetails);
        })
        .catch(error => {
          console.error('Une erreur s\'est produite lors de la récupération des détails de l\'utilisateur.');
        });
    };

}, []);

useEffect(() => {
  setResultats([...trajets, ...colis].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
  }));
}, [trajets, colis]);

  const handleCheckboxChange =  () => {
    const params = {
      depart: depart,
      arrivee: arrivee,
      date: date,
      nbPersonne: nbPersonne,
      sortBy: 'tarif'
    };
      console.log(checkboxChecked);
    axios
      .get('https://test-api.bookair.space/api/trajetsMoinsCher', { params })
      .then(response => {
        const trajets = response.data;
        console.log(trajets);
        setTrajets(trajets);
        setResultats(trajets);

        const userIds = trajets.map(trajet => trajet.user_id);
        fetchUserDetails(userIds);
        
      })
      .catch(error => {
        console.error('Une erreur s\'est produite lors de la requête de recherche.');
      });
    
  };
  
  const handleCheckboxChecked = (event) => {
    const checked = event.target.checked;
    setCheckboxChecked(checked);
    if (checked) {
      console.log("affichage");
      handleCheckboxChange();
    } else {
      console.log("Décoché");
      handleRecherche();
      
    }
  };
  
  
  const handleMaxTwoPersonsChange = (event) => {
    const checked = event.target.checked;
    setMaxTwoPersonsChecked(checked);
    
    let filteredTrajets = trajets;
  
    if (checked) {
      console.log("Max 2 personnes sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox1 === 1);
    }
  
    if (animauxChecked) {
      console.log("Animaux sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox2 === 1);
    }

    if (climatisationChecked) {
      console.log("climatisation sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox3 === 1);
    }

    if (priseChecked) {
      console.log("prise sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox4 === 1);
    }
    if (siegeChecked) {
      console.log("siege sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox5 === 1);
    }

    setResultats(filteredTrajets);

  };
  
  const handleAnimauxChange = (event) => {
    const checked = event.target.checked;
    setAnimauxChecked(checked);
    
    let filteredTrajets = trajets;
  
    if (maxTwoPersonsChecked) {
      console.log("Max 2 personnes sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox1 === 1);
    }
  
    if (checked) {
      console.log("Animaux sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox2 === 1);
    }

    if (climatisationChecked) {
      console.log("climatisation sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox3 === 1);
    }
    
    if (priseChecked) {
      console.log("prise sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox4 === 1);
    }
    if (siegeChecked) {
      console.log("siege sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox5 === 1);
    }

    setResultats(filteredTrajets);
  };
  
  const handleClimatisationChange = (event) => {
    const checked = event.target.checked;
    setClimatisationChecked(checked);
    
    let filteredTrajets = trajets;
  
    if (maxTwoPersonsChecked) {
      console.log("Max 2 personnes sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox1 === 1);
    }
    if (animauxChecked) {
      console.log("Animaux sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox2 === 1);
    }
    if (checked) {
      console.log("climatisation sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox3 === 1);
    }
    
    if (priseChecked) {
      console.log("prise sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox4 === 1);
    }
    if (siegeChecked) {
      console.log("siege sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox5 === 1);
    }
    setResultats(filteredTrajets);

  };

  const handlePriseChange = (event) => {
    const checked = event.target.checked;
    setPriseChecked(checked);

    let filteredTrajets = trajets;

    if (maxTwoPersonsChecked) {
      console.log("Max 2 personnes sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox1 === 1);
    }
    if (animauxChecked) {
      console.log("Animaux sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox2 === 1);
    }
    if (climatisationChecked) {
      console.log("climatisation sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox3 === 1);
    }
    if (checked) {
      console.log("prise sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox4 === 1);
    }

    if (siegeChecked) {
      console.log("siege sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox5 === 1);
    }
    setResultats(filteredTrajets);
  };

  const handleSiegeChange = (event) => {
    const checked = event.target.checked;
    setSiegeChecked(checked);

    let filteredTrajets = trajets;

    if (maxTwoPersonsChecked) {
      console.log("Max 2 personnes sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox1 === 1);
    }
    if (animauxChecked) {
      console.log("Animaux sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox2 === 1);
    }

    if (climatisationChecked) {
      console.log("climatisation sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox3 === 1);
    }
    if (priseChecked) {
      console.log("prise sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox4 === 1);
    }
    if (checked) {
      console.log("siege sélectionné");
      filteredTrajets = filteredTrajets.filter((trajet) => trajet.checkbox5 === 1);
    }
    setResultats(filteredTrajets);

  };

  const handleClick = (id) => {
    const trajetId= id;
    const redirectUrl = `/tripdetails2?id=${encodeURIComponent(trajetId)}`;
    window.location.href = redirectUrl;
};


function removePostalCode(address) {
  return address.replace(/\d+/, '').trim();
}


function extractCity(adresse) {
  const adresseSplit = adresse.split(',');
  if (adresseSplit.length >= 2) {
    const ville = adresseSplit[adresseSplit.length - 2].trim();
    return ville;
  } else {
    return null;
  }
}


function convertMonthToString(date) {
  const monthNumber = new Date(date).getMonth() + 1;
  const monthNames = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  
  if (monthNumber >= 1 && monthNumber <= 12) {
    return monthNames[monthNumber - 1];
  } else {
    return 'Mois invalide';
  }
  }

  function extractDay(date){
           
  const day = new Date(date).getDate();
  return day;
  }

  function extractDayOfWeek(date) {
  const dayOfWeekNumber = new Date(date).getDay();
  const dayNames = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
      return dayNames[dayOfWeekNumber];
  }

  const isResultFound = resultats.length > 0; 
  console.log("issssssss",isResultFound);
  let dateToShow = null;

  if (isResultFound) {
    dateToShow = (
      <div className='date-search'>
        <p>{extractDay(date)} {convertMonthToString(date)}</p>
      </div>
    );
  }

    return (
     
        <div className="recherche-container">
        <div>
          <h1 className="title1">Trouvez des trajets partagés en toute simplicité</h1>
          <div className="search-bar1">
            <div className="search-input-container1">
         
  <input className="search-input with-icons1" type="text" placeholder="Départ" id="depart-input" defaultValue={depart}onChange={event => setDepart(event.target.value)} />
            </div>
      
              <>
                <hr className="search-separator" />
                <div className="search-input-container">
                  <input className="search-input with-icons2" type="text" placeholder="Arrivée" id="arrivee-input" defaultValue={arrivee} onChange={event => setArrivee(event.target.value)}/>
                </div>
                <hr className="search-separator" />
                <div className="search-input-container">
                <input className="search-input with-icons3" type="date" placeholder="Date"  defaultValue={date} onChange={event => setDate(event.target.value)} />
                </div>
                <div className="search-input-container">
                  <input className="search-input with-icons4" type="text" placeholder="1"  defaultValue={nbPersonne} onChange={event => setNbPersonne(event.target.value)} />
                </div>
                <button className="search-button1" onClick={handleRecherche}>Rechercher</button>
              </>
          </div>

          <div className='trier'>
           <h1 className='trie1'> Trier par :  </h1>
        <div className="section1">
        <img src={heure} alt="icone" className="icone1" />
        <span className="filtre1">Trajet le plus court</span>
        <input type="checkbox" className="checkbox1" />
      </div>
     
     <div className='section1'>  <img src={plustot} alt="icone" className="icone1" />
        <span className="filtre1">Départ le plus tot</span>
        <input type="checkbox" className="checkbox2" /></div>
       
    

      <div className="section1">
        <img src={moinscher} alt="icone" className="icone1" />
        <span className="filtre1">Moin cher</span>
        <input type="checkbox" className="checkbox3" checked={checkboxChecked} onChange={handleCheckboxChecked}/>
      </div>
        </div>

        </div>
        <hr className="ligne" /> 

        <div className='trier'>
           <h1 className='trie2'> Service et équipements : </h1>
        <div className="section1">
        <img src={personne} alt="icone" className="icone1" />
        <span className="filtre1"> Max 2 personnes</span>
        <input type="checkbox" className="checkbox4"  checked={maxTwoPersonsChecked}
          onChange={handleMaxTwoPersonsChange} />
      </div>
      <div className="section1">
        <img src={anime} alt="icone" className="icone1" />
        <span className="filtre1">Animaux de compagnie</span>
        <input type="checkbox" className="checkbox5" checked={animauxChecked}
           onChange={handleAnimauxChange}/>
      </div>
      <div className="section1">
        <img src={clim} alt="icone" className="icone1" />
        <span className="filtre1">Climatisation</span>
        <input type="checkbox" className="checkbox6" checked={climatisationChecked}
           onChange={handleClimatisationChange}/>
      </div>
      <div className="section1">
        <img src={prise} alt="icone" className="icone1" />
        <span className="filtre1">Prise éléctrique</span>
        <input type="checkbox" className="checkbox7" checked={priseChecked}
           onChange={handlePriseChange} />
      </div>

      <div className="section8">
        <img src={siege} alt="icone" className="icone1" />
        <span className="filtre1">Siege inclinable</span>
        <input type="checkbox" className="checkbox8" checked={siegeChecked}
           onChange={handleSiegeChange}/>
      </div>

        </div>
        <div className="ligne-verticale"></div> 
        <div className='resultat'> Résultat</div>

<div className="resultats-container">


{isResultFound ? (
      
        <>
          {dateToShow}

          {  resultats.map(trajet => (
   <div >
    <div  onClick={() => handleClick(trajet.id)}  style={{ height: '200px', width : '560px' }} className="triangle-container" key={trajet.id}>
      <div className="triangle-content">
        <p className="departureArrival">
          
        {removePostalCode(extractCity(trajet.depart))}
          <br />
          {removePostalCode(extractCity(trajet.arrivee))}
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft : '270px' }}>
        {trajet.nb_personne !== undefined ? (
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={passenger} alt="passager" className="passengerIcon" style={{ height: '28px', width: '28px' }} />
                                              <div>
              
                                                <p className='text_liste'>Trajet de covoiturage :</p>
                                                </div>
                                                 <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                   <p className='texte_passager' style={{ color: '#130C4D', marginRight: '5px' }}>{trajet.nb_personne} passager</p>
                                                   
                                                 </div>
                                                 </div>
                                        </>
                                    ) : (
                                         <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={imageDuColis} alt="colis" className="colisIcon" style={{ marginLeft: '10px' }} />
                                        <p className='text_liste'>Trajet livraison de colis </p>
                                        </div>
                                    )}
       </div>
        <p className="price-search">{Math.round(trajet.tarif)}€</p>
        <div className="driver">
          <img src={profil} alt="icone" className="profilicon" />
          <p className="driver-info">
            {userInfo[trajet.user_id]?.name} {userInfo[trajet.user_id]?.firstName}
          </p>
        </div>
        <div className="services-search2">
          {trajet.checkbox1 === 1 && <img src={personne} alt="icone1" className="service-search2" />}
          {trajet.checkbox2 === 1 && <img src={anime} alt="icone2" className="service-search2" />}
          {trajet.checkbox3 === 1 && <img src={clim} alt="icone3" className="service-search2" />}
          {trajet.checkbox4 === 1 && <img src={prise} alt="icone4" className="service-search2" />}
          {trajet.checkbox5 === 1 && <img src={siege} alt="icone5" className="service-search2" />}
        </div>
      </div>
    </div>
    </div>
    
  ))}
        </>
      ) : (
        // Contenu pour aucun résultat trouvé
        <div>
          <p className='noresulttext'>Aucun résultat trouvé.</p>
          <img src={result} alt="noresult" className="noresult" />
        </div>
      )}

    </div>
        
    </div>

    );
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg'
  })(Recherche);

  