import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { useTransition, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './accueil.css';
import image1 from '../Public/image1.svg';
import image2 from '../Public/image2.svg';
import image3 from '../Public/image3.svg';
import image4 from '../Public/image4.svg';
import axios from 'axios';

const Accueil = ({ google }) => {
  const images = [
    { id: 1, src: image1, width: '700px', height: '550px' },
    { id: 2, src: image2, width: '700px', height: '550px' },
    { id: 3, src: image3, width: '700px', height: '550px' },
    { id: 4, src: image4, width: '700px', height: '550px' },
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [iterationCount, setIterationCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedGender, setSelectedGender] = useState('');
  const [showForm, setShowForm] = useState(false);

  
  const [depart, setDepart] = useState('');
  const [arrivee, setArrivee] = useState('');
  const [date, setDate] = useState('');
  const [nbPersonne, setNbPersonne] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const [showDialog, setShowDialog] = useState(false);
  const [missingAttributes, setMissingAttributes] = useState([]);
  const [formData, setFormData] = useState({
    gender: '',
    date_of_birth: '',
    address: ''
  });

  useEffect(() => {
		// Initialisation de l'autocomplétion pour le champ de départ
		const departAutocomplete = new google.maps.places.Autocomplete(
		  document.getElementById('depart-input')
		);
	
		departAutocomplete.addListener('place_changed', () => {
		  const place = departAutocomplete.getPlace();
		  setDepart(place.formatted_address);
		});
	
		// Initialisation de l'autocomplétion pour le champ d'arrivée
		const arriveeAutocomplete = new google.maps.places.Autocomplete(
		  document.getElementById('arrivee-input')
		);
	
		arriveeAutocomplete.addListener('place_changed', () => {
		  const place = arriveeAutocomplete.getPlace();
		  setArrivee(place.formatted_address);
		});
	  }, [google]);

  const handleRechercher = () => {
		if (!depart || !arrivee || !date || !nbPersonne) {
			setErrorMessage('Veuillez remplir tous les champs');
		  } 
	  else{	  	
	  setErrorMessage('');	
      window.location.href = `/search2?depart=${depart}&arrivee=${arrivee}&date=${date}&nbPersonne=${nbPersonne}`;
	} 
	};

  useEffect(() => {
    const timer = setTimeout(() => {
      if (iterationCount < 3 && !isHovered) {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIterationCount((count) => count + 1);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, iterationCount, images.length, isHovered]);

  const handleMouseEnter = () => {
    setCurrentImageIndex(0);
    setIterationCount(0);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };



  const transitions = useTransition(currentImageIndex, {
    from: { opacity: 0, position: 'absolute', left: 0, top: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }


  }, []);

 
  return (
    <div className="home-container">

      <div className="images-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {transitions((style, index) => (
          <animated.img
            key={images[index].id}
            src={images[index].src}
            alt={`Image ${index + 1}`}
            className="animated-image"
            style={{ ...style, width: images[index].width, height: images[index].height }}
          />
        ))}
      </div>
      <div className="content-container">
        <div>
          <h1 className="title"> Trouvez des trajets partagés en toute simplicité </h1>
          <div className="search-bar">
            <div className="search-input-container">
              <input className="search-input with-icon1" type="text" placeholder="Départ" value={depart}
                  onChange={(e) => setDepart(e.target.value)} id="depart-input"/>
            </div>
            <hr className="search-separator" />
            <div className="search-input-container">
              <input className="search-input with-icon2" type="text" placeholder="Arrivée" value={arrivee}
                    onChange={(e) => setArrivee(e.target.value)} id="arrivee-input"/>
            </div>
            <hr className="search-separator" />
            <div className="search-input-container">
              <input className="search-input with-icon3" type="date" placeholder="Date" value={date}
                   onChange={(e) => setDate(e.target.value)} />
            </div>
            <div className="search-input-container">
              <input className="search-input with-icon4" type="text" placeholder="1" value={nbPersonne}
                    onChange={(e) => setNbPersonne(e.target.value)} />
            </div>
            <button className="search-button" onClick={handleRechercher}>
              Rechercher
            </button>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
         
          <div className='send_package'>
      <h1>
        <Link to="/searchpackage" style={{ textDecoration: 'underline' }}>
          Souhaitez-vous envoyer un colis ?
        </Link>
      </h1>
    </div>

          <h1 className="trips"> Les trajets les plus populaires : </h1>
          <div className="mostpopular">
            <div className="first">
              <h1 className='popular1'>Lille → Paris</h1>
              <h1 className='popular2'>Paris → Lille</h1>
              <h1 className='popular3'>Grenoble → Lyon</h1>
              <h1 className='popular4'>Lyon → Grenoble</h1>
              <h1 className='popular5'>Reims → Paris</h1>
              <h1 className='popular6'>Paris → Reims</h1>
            </div>

            <div className="second">
              <h1 className='popular7'>Paris → Lyon</h1>
              <h1 className='popular8'>Lyon → Paris</h1>
              <h1 className='popular9'>Rennes → Paris</h1>
              <h1 className='popular10'>Paris → Rennes</h1>
              <h1 className='popular11'>Marseille → Paris</h1>
              <h1 className='popular12'>Paris → Paris</h1>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg'
  })(Accueil);
