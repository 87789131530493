// Profile.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import profil from './profil.png';
import DatePicker from 'react-datepicker';
import { GoogleApiWrapper} from 'google-maps-react';
import './profile.css';
import star from './star.png';
import add from './plus.png';
import edit from './edit.png';
import camera from './camera.png';

const Profile = ({ google }) => {
  const [newName, setNewName] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [user, setUser] = useState(null);
  const [userVehicles, setUserVehicles] = useState([]);
  const [text, setText] = useState('Ma bio');
  const [bio, setBio] = useState('');
const [travelPreferences, setTravelPreferences] = useState([]);


  const [uploadedImage, setUploadedImage] = useState(null);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [showDrivingLicence, setShowDrivingLicence] = useState(false);
  const [showPieceIdentite, setShowPieceIdentite] = useState(false); // Nouvelle section : Ajouter une pièce d'identité
  const [showCarpoolingInfo, setShowCarpoolingInfo] = useState(false); // Nouvelle section : Informations spécifiques au covoiturage
  const [showDriverStats, setShowDriverStats] = useState(false); // Nouvelle section : Statistiques du conducteur
  const [showPassengerReviews, setShowPassengerReviews] = useState(false); // Nouvelle section : Avis des passagers
  const [showTravelPreferences, setShowTravelPreferences] = useState(false); // Nouvelle section : Options de préférences de voyage
  const [showVehicleDetails, setShowVehicleDetails] = useState(false); // Nouvelle section : Détails du véhicule
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [showForm, setShowForm] = useState(false);


  const [isEditing, setIsEditing] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [BirthDate, setBirthDate] = useState(null);
  const [selectedGender, setSelectedGender] = useState('');
  const [CodePostale, setCodePostale] = useState('');
  const [adresse, setAdresse] = useState('');
  const [dateDeNaissance, setDateDeNaissance] = useState('');


  const handleSend = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/complete_register',
        {
          adresse: adresse,
          date_of_birth: dateDeNaissance,
          gender: selectedGender
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 10000, 
        }
      );

      console.log('Données enregistrées:', response.data);
  
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement des données:', error);
  
      if (axios.isCancel(error)) {
        console.log('La requête a été annulée:', error.message);
      }
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);

  useEffect(() => {
    const storedImage = localStorage.getItem('');
    if (storedImage) {
      setUploadedImage(storedImage);
    }
  }, []);
  
  useEffect(() => {
    if (google && showForm) {
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('adresse-input'),
        {
          componentRestrictions: { region: 'eu' }
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.formatted_address) {
          setAdresse(place.formatted_address);
          extractCityAndPostalCode(place.formatted_address);
        }
      });
    }
  }, [google, showForm]);
  

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get('https://test-api.bookair.space/api/getauthentificateduser', config);
        setUser(response.data.user);
        console.log(response.data.user);
  
        if (response.data.user && response.data.user.bio) {
          setText(response.data.user.bio);
        } else {
          setText('Ma bio');
        }
  
        if (response.data.user.preferences) {
          setSelectedPreferences(response.data.user.preferences.map(pref => pref.name));
          console.log('User preferences:', selectedPreferences);
        }
      } catch (error) {
        console.error('Failed to fetch authenticated user:', error);
      }
    };
  
    fetchUser();
  }, []);
  
  useEffect(() => {
    console.log('User preferences:', selectedPreferences);
  }, [selectedPreferences]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
        localStorage.setItem('uploadedImage1', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const inputFileRef = useRef(null);

  const handleButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setNewFirstName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setNewPhoneNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordError(false);
  };

  const handleUpdateUser = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo',
        {
          name: newName,
          firstName: newFirstName,
          phoneNumber: newPhoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };

  const handleUpdateUser1 = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo1',
        {
          firstName: newFirstName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };

  const handleUpdateUser2 = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo2',
        {
          phoneNumber: newPhoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };


  const handleDateChange = (date) => {
    setDateDeNaissance(date);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleUpdateUser3 = async () => {
    if (currentPassword === '') {
      setPasswordError(true);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo3',
        {
          password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };

  const handleRestart = () => {
    handleImageClear();
    window.location.href = '/drivinglicence';
  };

  const handleImageClear = () => {
    setUploadedImage(null);
    localStorage.removeItem('uploadedImage');
  };

  useEffect(() => {
    const fetchUserVehicles = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get('https://test-api.bookair.space/api/getUserVehicules', config);
        setUserVehicles(response.data.vehicles);
        console.log(response.data.vehicles);
      } catch (error) {
        console.error('Failed to fetch user vehicles:', error);
      }
    };

    fetchUserVehicles();
  }, []);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSave = async () => {
    setIsEditing(false);
    setIsSaved(true);
    console.log('Texte enregistré:', text);
    
    try {
      const token = localStorage.getItem('token'); 
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateBio',
        { bio: text, preferences: selectedPreferences },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      
      console.log('Bio enregistrée dans la base de données:', response.data);
      setShowTravelPreferences(false);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la bio:', error);
    }
  };
  
  const handlePreferenceChange = (e) => {
    const preference = e.target.value;
    if (e.target.checked) {
      setSelectedPreferences((prevPreferences) => [...prevPreferences, preference]);
    } else {
      setSelectedPreferences((prevPreferences) =>
        prevPreferences.filter((pref) => pref !== preference)
      );
    }
  };


  const extractCityAndPostalCode = (address) => {
    const components = address.split(', ');

    if (components.length >= 3) {
        const postalCode = components[components.length - 2].split(' ')[0];
        const city = components[components.length - 3];

        setCodePostale(postalCode);
    }
};
function extraireVille(adresse) {
    const adresseSplit = adresse.split(',');
    if (adresseSplit.length >= 2) {
      const ville = adresseSplit[adresseSplit.length - 2].trim();
      return ville;
    } else {
      return null;
    }
  }

  function removePostalCode(address) {
    return address.replace(/\d+/, '').trim();
  }
  
  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }
    return false;
  };

  

  

  return (
    <div className='profile-container'>
      <div className="profile-info">
        <div className="profile-name">
          {user && (
            <>
              <div className='profile-details'>
                <p className='idf1'>{user.name} {user.firstName}</p>
                <p className='user-email'>E-mail: {user.email}</p>
                <p className='user-phone'>Téléphone: {user.phoneNumber}</p>  
              </div>
            </>
          )}
        </div>
        {text && (
        <div className="user-bio">
          <p className="user-bio-text">{text}</p>
        </div>
      )}

{selectedPreferences.length > 0 && (
  <div className="user-travel-preferences">
    <p className="user-preferences-title">Options de préférences de voyage :</p>
    <ul>
      {selectedPreferences.map((pref, index) => (
        <li className='user-preferences-list' key={index}>{pref}</li>
      ))}
    </ul>
  </div>
)}

      {/* Afficher les options de préférences de voyage si elles existent */}
      {travelPreferences.length > 0 && (
        <div className="user-travel-preferences">
          <p className="user-preferences-title">Options de préférences de voyage :</p>
          <ul>
            {travelPreferences.map((pref, index) => (
              <li className='user-preferences-list' key={index}>{pref}</li>
            ))}
          </ul>
        </div>
      )}
        {uploadedImage ? (
  <img src={uploadedImage} alt="Profile" className="profile-image" />
) : (
  <div className="profile-image-container">
    <img src={profil} alt="Profile" className="profile-image" />
    <img src={camera} alt="Camera" className="camera-icon" onClick={handleButtonClick} />
  </div>
)}

        <hr className="phorizontal-line" />
        <div>
        <div className="profile-buttons">
          <div className="upload-button" onClick={handleButtonClick}>
            Ajouter une photo de profil
          </div>
          <input
            type="file"
            ref={inputFileRef}
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      <hr className="phorizontal-line" />
      <div className="profile-buttons">
  <div onClick={() => setShowForm(!showForm)}>Compléter mes informations personnelles</div>
</div>
{showForm && (
  <div className="personal-info-form">
    <form>
      <div className="input-container">
        <DatePicker
              className='bday'
              selected={BirthDate}
              onChange={date => setBirthDate(date)} 
              placeholderText="Date de naissance"
              dateFormat="dd/MM/yyyy"
              isClearable
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              scrollableMonthYearDropdown
              shouldCloseOnSelect={false}
            
      />
      
       <select className='select-gender' 
      value={selectedGender} 
      onChange={handleGenderChange}>
        <option value="">Sélectionnez le genre</option>
        <option value="homme">Homme</option>
        <option value="femme">Femme</option>
      </select>

       </div>

      <div className="form-group">
      <input
        className='adresse-user'
		    type="text"
		    placeholder="Adresse"
        id="adresse-input"/>

      </div>

      <div className='input-container'>

      <input
        className='ville'
		    type="text"
		    placeholder="Ville"	  
        value={adresse ? removePostalCode(extraireVille(adresse)) : ''}
		  />
     
      <input
            className='code-postale'
			type="text"
			placeholder="Code postale"
            value={CodePostale}
            onChange={(e) => setAdresse(e.target.value)}
		  />
      </div>

      <button  type="submit" className="btn btn-primary" onClick={handleSend}>
                   Enregistrer
      </button>
    </form>
  </div>
)}
      <hr className="phorizontal-line" />

      <div className="profile-buttons">
        <div onClick={() => setShowPersonalInfo(!showPersonalInfo)}>Modifier les informations personnelles</div>
      </div>

      {showPersonalInfo && (
        <div>
          <div className='bottom-text'>Modifier les informations personnelles :</div>
          <div className='modification'>
            <div className='text1'>
              Nom
              <input
                className='side-textarea'
                defaultValue={user.name}
                onChange={handleNameChange}
              />
            </div>
            <div className='text2'>
              Prénom
              <input
                className='side-textarea'
                defaultValue={user.firstName}
                onChange={handleFirstNameChange}
              />
            </div>
            <div className='text3'>
              Numéro de téléphone
              <input
                className='side-textarea'
                defaultValue={user.phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <div className='text4'>
              Ancien mot de passe
              <input
                className='side-textarea'
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className='text5'>
              Nouveau mot de passe
              <input
                className='side-textarea'
                value={newPassword}
                type="password"
                onChange={handlePasswordChange}
              />
            </div>
            <button className='edit-infos' onClick={() => {
              handleUpdateUser();
              handleUpdateUser1();
              handleUpdateUser2();
              handleUpdateUser3();
            }}>Enregistrer</button>
          </div>
        </div>
      )}

      <hr className="phorizontal-line" />

      <div className="profile-buttons">
        <div onClick={() => {
          if (localStorage.getItem('uploadedImage')) {
            setShowDrivingLicence(!showDrivingLicence);
          } else {
            window.location.href = '/drivinglicence';
          }
        }}>
          Mon permis de conduire
        </div>
      </div>

      {showDrivingLicence && (
        <div>
          {localStorage.getItem('uploadedImage') && (
            <div>
              <img className='permis-image' src={localStorage.getItem('uploadedImage')} alt="Permis de conduire" />
              <button className="restart" onClick={handleRestart}>
                Recommencer
              </button>
            </div>
          )}
        </div>
      )}

      <hr className="phorizontal-line" />

      {/* Nouvelle section : Ajouter une pièce d'identité */}
      <div className="profile-buttons">
        <div onClick={() => {
          if (localStorage.getItem('uploadedPieceIdentite')) {
            setShowPieceIdentite(!showPieceIdentite);
          } else {
            window.location.href = '/idcard';
          }
        }}>
          Ma pièce d'identité
        </div>
      </div>

      {showPieceIdentite && (
        <div>
          {localStorage.getItem('uploadedPieceIdentite') && (
            <div>
              <img className='piece-identite-image' src={localStorage.getItem('uploadedPieceIdentite')} alt="Pièce d'identité" />
              <button className="remove-piece-identite" >
                Supprimer la pièce d'identité
              </button>
            </div>
          )}
        </div>
      )}

      <hr className="phorizontal-line" />

            <div className="profile-buttons">
        <div className='text-profile0' onClick={() => setShowTravelPreferences(!showTravelPreferences)}>Options de préférences de voyage</div>
      </div>

  {showTravelPreferences && (
  <div className="bio-and-preferences">
    <div>
      <div>
        <div className='text-profile1'>
          Bio
          <textarea
            className='bio-textarea'
            value={text} 
            onChange={handleChange} 
          />
        </div>
      </div>
      <div className='text-profile2'>
        Options de préférences de voyage
        <label className='profile-labe1'>
            Musique
              <input
                className='input1'
                type="checkbox"
                value="Musique"
                checked={selectedPreferences.includes("Musique")}
                onChange={handlePreferenceChange}
              />
             
            </label>

            <label className='profile-labe2'>
            Animaux de compagnie acceptés 
              <input
              className='input2'
                type="checkbox"
                value="Animaux de compagnie acceptés "
                checked={selectedPreferences.includes("Animaux de compagnie acceptés ")}
                onChange={handlePreferenceChange}
              />
             
            </label>

            <label className='profile-labe3'>
            Climatisation/Chauffage
              <input
                className='input3'
                type="checkbox"
                value="Climatisation/Chauffage"
                checked={selectedPreferences.includes("Climatisation/Chauffage")}
                onChange={handlePreferenceChange}
              />
             
            </label>

            <label className='profile-labe4'>
            Flexibilité des heures de départ/retour 
              <input
                className='input4'
                type="checkbox"
                value="Flexibilité des heures de départ/retour "
                checked={selectedPreferences.includes("Flexibilité des heures de départ/retour")}
                onChange={handlePreferenceChange}
              />
              
            </label>
   
            <label className='profile-labe5'>
            Non fumeurs
              <input
              className='input5'
                type="checkbox"
                value="Non fumeurs"
                checked={selectedPreferences.includes("Non fumeurs")}
                onChange={handlePreferenceChange}
              />
            
            </label>
      
        <button className="confirm" onClick={handleSave}>
          Enregistrer
        </button>
      </div>
    </div>
  
</div>
)}
      <hr className="phorizontal-line" />
     

{/* Nouvelle section : Informations spécifiques au covoiturage */}
<div className="profile-buttons">
  <div onClick={() => setShowCarpoolingInfo(!showCarpoolingInfo)}>Informations spécifiques au covoiturage</div>
</div>

{showCarpoolingInfo && (
  <div>
    {/* Ajoutez ici les informations spécifiques au covoiturage */}
  </div>
)}

<hr className="phorizontal-line" />

{/* Nouvelle section : Statistiques du conducteur */}
<div className="profile-buttons">
  <div onClick={() => setShowDriverStats(!showDriverStats)}>Statistiques du conducteur</div>
</div>

{showDriverStats && (
  <div>
    {/* Ajoutez ici les statistiques du conducteur */}
  </div>
)}

<hr className="phorizontal-line" />

{/* Nouvelle section : Avis des passagers */}
<div className="profile-buttons">
  <div onClick={() => setShowPassengerReviews(!showPassengerReviews)}>Avis des passagers</div>
</div>


{showPassengerReviews && (
  <div>
    {/* Ajoutez ici les avis des passagers */}
  </div>
)}
      {showVehicleDetails && (
        <div>
          {/* Ajoutez ici les détails du véhicule */}
        </div>
      )}

      <hr className="phorizontal-line" />

      {/* Autres sections existantes */}
      <div className="profile-buttons">
        <div onClick={() => (window.location.href = '/addvehicule')}>Ajouter un véhicule</div>
      </div>

      <div>
        <hr className="phorizontal-line" />
    
      </div>
    </div>
  </div>
  );
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg'
  })(Profile);
