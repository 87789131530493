import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import 'react-datepicker/dist/react-datepicker.css';
import fleche from './fleche.png'
import './etape5.css';

const Etape5 = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [time, setTime] = useState("08:00");
  const [depart, setDepart] = useState('');
  const [arrivee, setArrivee] = useState('');
  const [distance, setDistance] = useState('');
  const [communes, setCommunes] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);
  const [mode, setMode] = useState('');
  const [annonce, setAnnonce] = useState('');
  
  
  let labelText;
  if (mode === '0') {
    labelText = "Quand et à quelle heure souhaitez-vous retrouver vos passagers?";
  } else if (mode === '1') {
    labelText = "Quand et à quelle heure souhaitez-vous récupérer le colis?";
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adresseDepart = params.get('adressedepart');
    setDepart(adresseDepart);
    const adresseArrivee = params.get('arrivee');
    setArrivee(adresseArrivee);
    const distance = params.get('distance');
    setDistance(distance);
    const communes = JSON.parse(params.get('communes'));
    setCommunes(communes);
    params.append('selectedRouteIndex', selectedRouteIndex); 
    setSelectedRouteIndex(selectedRouteIndex);
    const mode = params.get('mode');
    setMode(mode);
    const annonce = params.get('annonce');
    setAnnonce(annonce);


  }, []);

  
  const handleContinuer = () => {
    if (!selectedDate || !time) {
      setShowErrorMessage(true);
    } else {
      if (mode === '0') {
        const params = new URLSearchParams();
        params.append('adressedepart', depart);
        params.append('arrivee', arrivee);
        params.append('distance', distance);
        params.append('communes', JSON.stringify(communes));
        params.append('date', selectedDate.toISOString());
        params.append('heure', time);
        const queryString = `?${params.toString()}`;
        window.location.href = `/step6${queryString}`;
      } else if (mode === '1') {
        const params = new URLSearchParams();
        params.append('adressedepart', depart);
        params.append('arrivee', arrivee);
        params.append('date', selectedDate.toISOString());
        params.append('heure', time);
        params.append('distance', distance);
        params.append('annonce', annonce);
        const queryString = `?${params.toString()}`;
        window.location.href = `/colis${queryString}`;
      }
    }
  };

  
  const handleGoBack = () => {
    window.history.back();
  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);


  return (
    <div className="datehour-container1">
             <img src={fleche} alt="Retour" className="comeback5" onClick={handleGoBack} />
        <label htmlFor="depart" className="datehour-label1">
        {labelText}
      </label>
      <div className="datepicker-container">
        <DatePicker 
          className='datepicker'
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          open={true}
        />
      </div>
      <label htmlFor="depart" className="label3">
      <hr className="horizontal-line6" />
      </label>
      <div className="timepicker-container">
      <input className="heuree" type="time" required  value={time} onChange={(e) => setTime(e.target.value)}/>
      </div>
      <button onClick={handleContinuer} className='tocontinuate1'>Continuer</button>
      {showErrorMessage && (
        <p className="error-message">Veuillez sélectionner une date et une heure</p>
      )}
    </div>
  );
};

export default Etape5;
