import React from 'react';
import { Link } from 'react-router-dom';
import fb from './fb.png';
import insta from './insta.png';
import twit from './twit.png';
import './footer.css';

const Footer = () => {
  return (
    
    <div className="footer">
        <footer className="footer-content">
         <nav>
            <ul>
                 <li> <Link to="/infos"> Informations générales </Link></li>
                 <li> <Link to="/contact"> Nous contacter </Link></li>
                 <li> <Link to="/home"><img src={fb} alt="Facebook" /></Link> </li>
                 <li> <Link to="/home"><img src={insta} alt="Instagram" /></Link> </li>
                 <li> <Link to="/home"><img src={twit} alt="twit" /></Link> </li>
        
        </ul>
       
        </nav>
        </footer>
  </div>
  
    
  );
};

export default Footer;
