import React from 'react';

const Infos = () => {
    return (
        <div>
            <h1> OKKK</h1>
        </div>
    );
};

export default Infos;