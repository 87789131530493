import React, { useState, useEffect, useRef } from 'react';
import { Map, GoogleApiWrapper, Marker, Polyline } from 'google-maps-react';
import { Autocomplete } from 'google-maps-react';
import iconImage from './marque.png';
import fleche from './fleche.png'
import './etape3.css';

const Etape3 = ({ google }) => {
  const [depart, setDepart] = useState('');
  const [arrivee, setArrivee] = useState('');
  const [routes, setRoutes] = useState([]);
  const [routeCities, setRouteCities] = useState([]);
  const [selectedRouteCities, setSelectedRouteCities] = useState([]);
  const [selectedRouteDistance, setSelectedRouteDistance] = useState(0);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);
  const [mode, setMode] = useState('');
  const [annonce, setAnnonce] = useState('');

  const [polylineOptions, setPolylineOptions] = useState({
    strokeOpacity: 0.8,
    strokeWeight: 2,
    strokeColor: '#FF0000',
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adressedepart = params.get('adressedepart');
    const arrivee = params.get('arrivee');
    const mode = params.get('mode');
    const annonce = params.get('annonce');

    console.log("l'annonce", annonce);

    setDepart(adressedepart);
    setArrivee(arrivee);
    setMode(mode);
    setAnnonce(annonce);
    
  }, []);

  useEffect(() => {
    if (depart && arrivee) {
      fetchRoutes();
    }
  }, [depart, arrivee]);



  const fetchRoutes = () => {
    const directionsService = new google.maps.DirectionsService();
    const geocoder = new google.maps.Geocoder();
  
    // Points d'origine et de destination
    const origin = depart;
    const destination = arrivee;
  
    // Effectuer une requête de direction avec l'option provideRouteAlternatives définie sur true
    const request = {
      origin,
      destination,
      travelMode: 'DRIVING',
      provideRouteAlternatives: true,
    };
  
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        // Récupérer tous les itinéraires
        const routes = result.routes;
  
        setRoutes(routes);
        setSelectedRouteIndex(0);
  
        // Récupérer les noms des communes pour chaque itinéraire
        const citiesPromises = routes.map((route) => {
          const citySet = new Set(); // Utiliser un ensemble pour stocker les communes uniques
          const promises = route.legs[0].steps.map((step) => {
            return new Promise((resolve) => {
              geocoder.geocode({ location: step.start_location }, (results, status) => {
                if (status === 'OK') {
                  const commune = results[0].address_components.find((component) =>
                    component.types.includes('locality')
                  );
                  if (commune) {
                    citySet.add(commune.long_name);
                  }
                }
                resolve();
              });
            });
          });
          promises.push(
            new Promise((resolve) => {
              geocoder.geocode({ location: route.legs[0].end_location }, (results, status) => {
                if (status === 'OK') {
                  const commune = results[0].address_components.find((component) =>
                    component.types.includes('locality')
                  );
                  if (commune) {
                    citySet.add(commune.long_name);
                  }
                }
                resolve();
              });
            })
          );
          return Promise.all(promises).then(() => Array.from(citySet));
        });
  
        Promise.all(citiesPromises).then((cities) => {
          setRouteCities(cities);
  
          console.log('Communes sur les itinéraires :');
          cities.forEach((routeCities, index) => {
            console.log(`Itinéraire ${index + 1}:`, routeCities);
          });
        });
      } else {
        console.error('Directions request failed due to ' + status);
      }
    });
  };
  

  const handleRouteSelection = (index) => {
    setSelectedRouteIndex(index);
    setPolylineOptions((prevOptions) => ({
      ...prevOptions,
      strokeColor: '#FF0000',
    }));
  };

  const mapRef = useRef(null);

  useEffect(() => {
    if (selectedRouteIndex !== null && routes.length > 0) {
      const selectedRoute = routes[selectedRouteIndex];

      const bounds = new google.maps.LatLngBounds();

      selectedRoute.overview_path.forEach((point) => {
        bounds.extend(point);
      });

      mapRef.current.map.fitBounds(bounds);
    }
  }, [selectedRouteIndex]);

  const handleContinuer = () => {
      
    if (selectedRouteIndex !== null) {
      const selectedRoute = routes[selectedRouteIndex];
      const selectedRouteCities = routeCities[selectedRouteIndex];
      const distance = selectedRoute.legs[0].distance.text;
      const params = new URLSearchParams();
      params.append('adressedepart', depart);
      params.append('arrivee', arrivee);
      params.append('communes', JSON.stringify(selectedRouteCities));
      params.append('selectedRouteIndex', selectedRouteIndex);
      params.append('mode', mode);
      params.append('distance', distance);
      params.append('annonce', annonce);
    
         
        const queryString = `?${params.toString()}`;
        window.location.href = `/step4${queryString}`;
     
       
       
      
  }
  
};

  const handleGoBack = () => {
    window.history.back();
  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
       <img src={fleche} alt="Retour" className="comeback4" onClick={handleGoBack} />
      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginTop: '60px' }}>
        <div style={{ marginRight: '0px', margin: 0 }}>
          {routes.map((route, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <input
                type="radio"
                name="routeSelection"
                checked={selectedRouteIndex === index}
                onChange={() => handleRouteSelection(index)}
                style={{ marginRight: '5px', transform: 'scale(0.6)', verticalAlign: 'middle', width: '150px' }}
              />
              <span
                className={selectedRouteIndex === index ? 'selected-route' : 'unselected-route'}
                style={{ fontSize: '15px', verticalAlign: 'middle' }}
              >
                {`Itinéraire ${index + 1} - Distance: ${route.legs[0].distance.text}`}
              </span>
              
            </div>
          ))}
 
              <button type="submit" onClick={handleContinuer} className="submit">Continuer</button>
        </div>

        <div style={{ flexGrow: 1 }}>
          <Map
            className="map-containerr"
            ref={mapRef}
            style={{ width: '700px', height: '700px', marginLeft: '600px', marginTop:'-50px', overflowX: 'hidden' }}
            google={google}
            zoom={10}
            initialCenter={{ lat: 48.8566, lng: 2.3522 }}
          >
            {selectedRouteIndex !== null && (
              <Polyline
                path={routes[selectedRouteIndex].overview_path}
                options={{ ...polylineOptions, strokeColor: '#FF0000' }}
              />
            )}

            {depart && (
              <Marker
                position={depart}
                icon={{
                  url: iconImage,
                  scaledSize: new google.maps.Size(40, 40),
                }}
              />
            )}

            {arrivee && (
              <Marker
                position={arrivee}
                icon={{
                  url: iconImage,
                  scaledSize: new google.maps.Size(40, 40),
                }}
              />
            )}
          </Map>
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBpwwe834bxk8lb-7MXdh3gQ1Pzuf1plmg',
})(Etape3);
