import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import 'react-datepicker/dist/react-datepicker.css';
import fleche from './fleche.png';
import axios from 'axios';
import './etape5.css';
import './edit1.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const Edit1 = () => {
  const [time, setTime] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [defaultDate, setDefaultDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [heure, setHeure] = useState('08:00');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [mode, setMode] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    const date = params.get('date');
    const heure = params.get('heure');
    const mode = params.get('mode');

    setId(id);
    setDate(date);
    setHeure(heure);
    setDefaultDate(new Date(date));
    setMode(mode);

    console.log('id du trajet :', id);
    console.log('date : ', date);
    console.log('time : ', heure);
  }, []);

  const [id, setId] = useState('');
  const [date, setDate] = useState();

  console.log('nfjknfjknf', defaultDate);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);

  const handleGoBack = () => {
    window.history.back();
  };

  function formatDate(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const handleUpdateDateAndTime = async () => {
    if (!selectedDate || !heure) {
      setShowErrorMessage(true);
      return;
    }

    setShowErrorMessage(false);

    try {
      const token = localStorage.getItem('token');
      let updateUrl = `https://test-api.bookair.space/api/updateDateTime/${id}`;

      if (mode === '1') {
        
        updateUrl = `https://test-api.bookair.space/api/updatePackageDateTime/${id}`;
      }

      const response = await axios.post(
        updateUrl,
        {
          date: formatDate(selectedDate),
          heure: heure,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Trip date and time updated:', response.data);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error updating trip date and time:', error);
    }
  };

  const handleButtonClick1 = () => {
    setDialogOpen(false);
    window.location.href = `/tripslist`;
  };

  return (
    <div className="datehour-container">
      <img src={fleche} alt="Retour" className="comeback5" onClick={handleGoBack} />
      <label htmlFor="depart" className="datehour-label">
        Modification de la date et de l'heure de départ
      </label>
      <div className="datepicker-container">
        <DatePicker
          className="datepicker"
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          open={true}
          defaultDate={defaultDate}
        />
      </div>
      <label htmlFor="depart" className="label3">
        <hr className="horizontal-line-edit" />
      </label>
      <div className="timepicker-container">
        <input className="heuree" type="time" required value={time} onChange={(e) => setHeure(e.target.value)} defaultValue={heure} />
      </div>
      <button className="tocontinuate1" onClick={handleUpdateDateAndTime}>
        Modifier
      </button>
      {showErrorMessage && <p className="error-message">Veuillez sélectionner une date et une heure</p>}

      {dialogOpen && (
        <div className="custom-dialog">
          <div className="dialog-content">
            <CheckCircleIcon style={{ color: 'green', fontSize: 48 }} />
            <Typography variant="h6">Date et heure modifié avec succès !</Typography>
            <Button onClick={handleButtonClick1}>OK</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit1;
