import React, {useEffect, useState} from 'react'
import cb from './cb.png'
import './cartebancaire.css'

const Cartebancaire = () => {
  const [cardholderName, setCardholderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Si le token n'existe pas, rediriger vers la page de connexion
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      // Si le token a expiré, rediriger vers la page de connexion
      window.location.href = '/login';
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

   

    console.log('Cardholder Name:', cardholderName);
    console.log('Card Number:', cardNumber);
    console.log('Expiry Month:', expiryMonth);
    console.log('Expiry Year:', expiryYear);

    setCardholderName('');
    setCardNumber('');
    setExpiryMonth('');
    setExpiryYear('');
  };

    return (
      <div className='carteb'>
      <label htmlFor="carte" className="label2">
        Entrer les informations de votre carte bancaire
      </label>
      <form className="form-container" onSubmit={handleSubmit}>
        <div>
          <label className='labelcb1' htmlFor="cardholderName">Nom du titulaire de la carte : </label>
          <input
            type="text"
            id="cardholderName"
            className="input-field"
            placeholder="Nom du titulaire de la carte"
            value={cardholderName}
            onChange={(e) => setCardholderName(e.target.value)}
            required
          />
        </div>
        <div className="cb-container">
          <img src={cb} alt="cb" className="cb" />
          <label htmlFor="cardNumber" className="labelcb2">
            Numéro de la carte :
          </label>
        </div>
        <input
          type="text"
          id="cardNumber"
          placeholder="Numéro de la carte"
          className="input-field input-with-icon"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          required
        />
     
        <div>
          <div className='div3'>
            <label className='labelcb3' htmlFor="expiryMonth">Mois d'expiration :</label>
          </div>
          <select
            id="expiryMonth"
            className="input-field"
            value={expiryMonth}
            onChange={(e) => setExpiryMonth(e.target.value)}
            required
          >
            <option value="">Sélectionnez un mois</option>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <option key={month} value={month.toString().padStart(2, '0')}>
                {month.toString().padStart(2, '0')}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="expiryYear">Année d'expiration</label>
          <select
            id="expiryYear"
            className="input-field"
            value={expiryYear}
            onChange={(e) => setExpiryYear(e.target.value)}
            required
          >
            <option value="">Sélectionnez une année</option>
            {Array.from({ length: 8 }, (_, i) => new Date().getFullYear() + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </form>
      <input
          type="text"
          placeholder="CVV"
          className="input-cvv"
         
        />
        <button type="submit" className="pay-button">Payer</button>
    </div>
    );
};

export default Cartebancaire;