import './App.css'
import { BrowserRouter } from 'react-router-dom'
import PublicRoute from './pages/Public/PublicRoute.js'
import Footer from './components/public/Footer'

function App() {
	return (
		<div className="App">
			<div className="itim">
				<BrowserRouter>
					<PublicRoute />
					<Footer />
				</BrowserRouter>
			</div>
			
		</div>
		
	)
}

export default App
