import React, { useState, useEffect } from 'react';
import './addvehicule.css';
import carImage from './covoiturage.png';
import fleche from './fleche.png'
import axios from 'axios';

const AddVehicule = () => {
    
  const [errors, setErrors] = useState({});  
  const [showSuccessDialog, setShowSuccessDialog] = useState(false); 
  const [color, setColor] = useState(''); 
  const colors = ['Bleu', 'Rouge', 'Noir', 'Gris', 'Blanc', 'Jaune', 'Orange', 'Vert'];

  const carBrands = [
    'Renault', 'Peugeot', 'Citroën', 'Volkswagen', 'Mercedes-Benz', 'BMW', 'Audi',
    'Ford', 'Opel', 'Dacia', 'Fiat', 'Toyota', 'Nissan', 'Seat', 'Volvo'
  ];
  
  const carModelsByBrand = {
    Renault: ['Clio', 'Mégane', 'Captur', 'Talisman'],
    Peugeot: ['208', '308', '3008', '508'],
    Citroën: ['C3', 'C4', 'C5 Aircross', 'C4 Picasso'],
    Toyota: ['Corolla', 'Camry', 'RAV4'],
    Honda: ['Civic', 'Accord', 'CR-V'],
    Ford: ['Fiesta', 'Focus', 'Kuga', 'Mondeo', 'Mustang'],
    Opel: ['Corsa', 'Astra', 'Insignia', 'Mokka'],
    Dacia: ['Sandero', 'Duster', 'Logan', 'Stepway'],
    Fiat: ['500', 'Punto', 'Tipo', '500X'],
    Seat: ['Ibiza', 'Leon', 'Ateca', 'Tarraco'],
    Volvo: ['V40', 'V60', 'XC40', 'XC60'],
    Nissan: ['Altima', 'Maxima', 'Rogue'],
    Chevrolet: ['Malibu', 'Cruze', 'Equinox', 'Tahoe'],
    BMW: ['3 Series', '5 Series', 'X3', 'X5'],
   'Mercedes-Benz': ['C-Class', 'E-Class', 'GLC', 'GLE'],
    Audi: ['A4', 'A6', 'Q5', 'Q7'],
    Volkswagen: ['Golf', 'Passat', 'Tiguan', 'Atlas'],
    Hyundai: ['Elantra', 'Sonata', 'Tucson', 'Santa Fe'],
    Kia: ['Optima', 'Sorento', 'Sportage', 'Telluride'],
    Subaru: ['Impreza', 'Legacy', 'Outback', 'Forester'],
    Lexus: ['ES', 'RX', 'NX', 'LS'],
    
  };

  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [availableModels, setAvailableModels] = useState([]);
  const [isLicensePlateValid, setIsLicensePlateValid] = useState(true); 

  function isValidEuropeanLicensePlate(licensePlate) {
    const cleanedLicensePlate = licensePlate.replace(/\s/g, '').toUpperCase();
    if (cleanedLicensePlate.length !== 7) {
      return false;
    }
    if (!/^[A-Z]{2}/.test(cleanedLicensePlate)) {
      return false;
   }
 
    if (!/^[A-Z0-9]{5}/.test(cleanedLicensePlate.slice(2))) {
      return false;
    }
    return true;
  }

  const handleBrandChange = (e) => {
    const brand = e.target.value;
    setSelectedBrand(brand);
    setSelectedModel('');
    setAvailableModels(carModelsByBrand[brand] || []);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
  
    const formData = {
      plaque_immatriculation: e.target.plaqueMatriculation.value,
      marque: e.target.marque.value,
      modele: e.target.modele.value,
      couleur: e.target.couleur.value,
      nbplace: parseInt(e.target.nbplace.value),
      annee: e.target.annee.value,
    };
  
    const isPlateValid = isValidEuropeanLicensePlate(formData.plaque_immatriculation);

    if (!isPlateValid) {
      setIsLicensePlateValid(false);
      return;
    }
   
    const errors = {};
    if (!formData.plaque_immatriculation) {
      errors.plaqueMatriculation = "Le champ Plaque d'immatriculation est requis";
    }
    if (!formData.marque) {
      errors.marque = "Le champ Marque est requis";
    }
    if (!formData.modele) {
      errors.modele = "Le champ Modèle est requis";
    }
    if (!formData.couleur) {
      errors.couleur = "Le champ Couleur est requis";
    }
 
    if (!formData.annee) {
      errors.annee = "Le champ Année est requis";
    }
  
   
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
  
   
    axios
      .post('https://test-api.bookair.space/api/addvehicules', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('Véhicule ajouté avec succès !');
        setShowSuccessDialog(true);
        setErrors({});
      })
      .catch((error) => {
        console.error('Erreur lors de l\'ajout du véhicule :', error);
      });
  };
  
  const handleButtonClick1=() => {
    setShowSuccessDialog(false)
    window.location.href = `/drivinglicence`;
  };

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);

  return (
  
    <div>
       <img src={fleche} alt="Retour" className="comeback-car" onClick={handleGoBack} />
          <div className='new-background'> 
           <img src={carImage} alt="Car" className="car-image"  style={{
    width: '300px', 
    height: '200px', 
    objectFit: 'cover', 
    marginBottom: '20px', 
  }} />
           </div>
          
           
    <div className="scrollable-content">
 <label htmlFor="depart" className="Addcar-label">
 Entrer les informations de votre véhicule
          </label>
    <div className="Addvehicule-container">
        
      <div className="row justify-content-center mt-5">
        <div className="col-lg-4">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="plaqueMatriculation" className="red-text">
                Plaque d'immatriculation
              </label>
              <input type="text" className="form-control" id="plaqueMatriculation"  placeholder="PLaque d'immatriculation"/>
              {errors.plaqueMatriculation && (
    <div className="invalid-feedback">{errors.plaqueMatriculation}</div>
  )}
  {!isLicensePlateValid && (
            <div className="invalid-feedback">Plaque d'immatriculation invalide</div>
          )}
            </div>
            <div className="form-group">
              <label htmlFor="marque" className="red-text">
                Marque
              </label>
            <select className="form-control" id="marque" value={selectedBrand} onChange={handleBrandChange}>
          <option value="">Sélectionner la marque </option>
          {carBrands.map((brand) => (
            <option key={brand} value={brand}>
              {brand}
            </option>
          ))}
        </select>
             
              {errors.marque && (
    <div className="invalid-feedback">{errors.plaqueMatriculation}</div>
  )}
            </div>
            <div className="form-group">
              <label htmlFor="modele" className="red-text" >
                Modèle
              </label>
              <select  className="form-control" id="modele" value={selectedModel} onChange={handleModelChange}>
          <option value="">Sélectionner un modèle</option>
          {availableModels.map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
              {errors.modele && (
    <div className="invalid-feedback">{errors.plaqueMatriculation}</div>
  )}
            </div>
            <div className="form-group">
              <label htmlFor="couleur" className="red-text">
                Couleur
              </label>
              <select
                className="form-control"
                id="couleur"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              >
                {colors.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
                <option value="autre">Autre</option>
              </select>
            </div>
            {color === 'autre' && (
              <div className="form-group">
                <label htmlFor="autreCouleur" className="red-text">
                  Autre couleur
                </label>
                <input type="text" className="form-control" id="autreCouleur" />
                {errors.plaqueMatriculation && (
    <div className="invalid-feedback">{errors.plaqueMatriculation}</div>
  )}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="annee" className="red-text">
                Année
              </label>
              <input type="text" className="form-control" id="annee" placeholder="Annèe"/>
              {errors.plaqueMatriculation && (
    <div className="invalid-feedback">{errors.plaqueMatriculation}</div>
  )}
            </div>
            <div className="form-group">
              <label htmlFor="nbplace" className="red-text">
                Nombre de place
              </label>
              <input type="text" className="form-control" id="nbplace" placeholder="Nombre de place"/>
              {errors.plaqueMatriculation && (
    <div className="invalid-feedback">{errors.plaqueMatriculation}</div>
  )}
            </div>
            <button type="submit" className="btn btn-primary">
              Enregistrer
            </button>
          </form>
        </div>
      </div>
      </div>
      {showSuccessDialog && (
        <div className="modal">
          <div className="modal-content">
            <p>Véhicule ajouté avec succès !</p>
            <button className="butt1" onClick={handleButtonClick1}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default AddVehicule;
