import React, { useEffect, useState } from 'react';
import './etape6.css';
import personne from './2pers.png'
import anim from './anim.png'
import siege from './siege.png'
import prise from './prise.png'
import clime from './clim.png'
import fleche from './fleche.png'


const Etape6 = () => {
  const [passengerCount, setPassengerCount] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [time, setTime] = useState("");
  const [depart, setDepart] = useState('');
  const [arrivee, setArrivee] = useState('');
  const [distance, setDistance] = useState('');
  const [communes, setCommunes] = useState([]);
  const [nbpersonne, setNbperosnne] = useState('');
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adresseDepart = params.get('adressedepart');
    setDepart(adresseDepart);
    const adresseArrivee = params.get('arrivee');
    setArrivee(adresseArrivee);
    const distance = params.get('distance');
    setDistance(distance);
    const communes = JSON.parse(params.get('communes'));
    setCommunes(communes);
    const date = new Date(params.get('date'));
    setSelectedDate(date);
    const heure = params.get('heure');
    setTime(heure);
    params.append('selectedRouteIndex', selectedRouteIndex); 
    setSelectedRouteIndex(selectedRouteIndex);

    console.log("la distance", distance);
  }, []);

  const handleIncrement = () => {
    if (passengerCount < 3) {
      setPassengerCount(passengerCount + 1);
    }
  };

  const handleDecrement = () => {
    if (passengerCount > 1) {
      setPassengerCount(passengerCount - 1);
    }
  };

 
	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(false);
	const [checkbox4, setCheckbox4] = useState(false);
	const [checkbox5, setCheckbox5] = useState(false);

  const handleCheckboxChange1 = (e) => {
		setCheckbox1(e.target.checked);
	  };
	
	  const handleCheckboxChange2 = (e) => {
		setCheckbox2(e.target.checked);
	  };
	
	  const handleCheckboxChange3 = (e) => {
		setCheckbox3(e.target.checked);
	  };
	
	  const handleCheckboxChange4 = (e) => {
		setCheckbox4(e.target.checked);
	  };
	
	  const handleCheckboxChange5 = (e) => {
		setCheckbox5(e.target.checked);
	  };

    const handleContinuer = () => {
         
          setPassengerCount(passengerCount);

          const params = new URLSearchParams();
          params.append('adressedepart', depart);
          params.append('arrivee', arrivee);
          params.append('distance', distance);
          params.append('communes', JSON.stringify(communes));
          params.append('date', selectedDate.toISOString()); 
          params.append('heure', time); 
          params.append('passengerCount', passengerCount);
          params.append('checkbox1', checkbox1);
          params.append('checkbox2', checkbox2);
          params.append('checkbox3', checkbox3);
          params.append('checkbox4', checkbox4);
          params.append('checkbox5', checkbox5);
        
          const queryString = `?${params.toString()}`;
          window.location.href = `/step7${queryString}`;
        
    };

    
    const handleGoBack = () => {
      window.history.back();
    };
  
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }
  
      const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
      const currentTime = new Date();
  
      if (currentTime >= tokenExpirationDate) {
        window.location.href = '/login';
      }
    }, []);

   
  return (
    <div className='etape6'>
       <img src={fleche} alt="Retour" className="comeback6" onClick={handleGoBack} />
    <div className="container6">
    <label className="label4">
      Combien de passagers pouvez-vous accepter ?
    </label>
    </div>
    <div className="passenger-counter">
      <button onClick={handleDecrement} className="counter-button">
        -
      </button>
      <span className="counter-value">{passengerCount}</span>
      <button onClick={handleIncrement} className="counter-button">
        +
      </button>
    </div>
    <div className="container5">
    <hr className="horizontal-line7" />
    <label className="label5">
      Voulez vous ajouter des services et des équipements ?
    </label>
			<div className="icon1-section">
				<img src={personne} alt="icone" className="icone" />
				<span className="eq1">Max 2 à l'intérieur</span>
				<input type="checkbox" className="checkboxl1" checked={checkbox1} onChange={handleCheckboxChange1} />
			</div>

			<div className="icon2-section">
				<img src={anim} alt="icone" className="icone2" />
				<span className="eq2">Animaux de compagnie</span>
				<input type="checkbox" className="checkboxl2" checked={checkbox2} onChange={handleCheckboxChange2}/>
			</div>

			<div className="icon3-section">
				<img src={siege} alt="icone" className="icone3" />
				<span className="eq3">Siège inclinable</span>
				<input type="checkbox" className="checkboxl3" checked={checkbox3} onChange={handleCheckboxChange3} />
			</div>

			<div className="icon4-section">
				<img src={clime} alt="icone" className="icone4" />
				<span className="eq4">Climatisation</span>
				<input type="checkbox" className="checkboxl4" checked={checkbox4} onChange={handleCheckboxChange4}/>
			</div>

			<div className="icon5-section">
				<img src={prise} alt="icone" className="icone5" />
				<span className="eq5">Prises électriques</span>
				<input type="checkbox" className="checkboxl5" checked={checkbox5} onChange={handleCheckboxChange5}/>
			</div>
      <button onClick={handleContinuer} className='tocontinuate2'>Continuer</button>
    </div>
  
  </div>
  
  );
};

export default Etape6;
