import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import './xservice.css';
import axios from 'axios'


import image1 from './image1.svg';
import image2 from './image2.svg';
import image3 from './image3.svg';
import image4 from './image4.svg';


const Xservices = () => {


  const images = [
    { id: 1, src: image1, width: '700px', height: '550px' },
    { id: 2, src: image2, width: '700px', height: '550px' },
    { id: 3, src: image3, width: '700px', height: '550px' },
    { id: 4, src: image4, width: '700px', height: '550px' },
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [iterationCount, setIterationCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      if (iterationCount < 3 && !isHovered) {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIterationCount((count) => count + 1);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, iterationCount, images.length, isHovered]);

  const handleMouseEnter = () => {
    setCurrentImageIndex(0);
    setIterationCount(0);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const transitions = useTransition(currentImageIndex, {
    from: { opacity: 0, position: 'absolute', left: 0, top: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });


  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [numeroTel, setNumeroTel] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [confirmationMotDePasse, setConfirmationMotDePasse] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
 
  const sendPost = () => {
    if (motDePasse !== confirmationMotDePasse) {
      setIsPasswordMatch(false);
      return; 
    }
		return axios
			.post('https://test-api.bookair.space/api/register', {
				name: nom,
				firstName: prenom,
				email: email,
				phoneNumber: numeroTel,
				password: motDePasse,
				password_confirmation: confirmationMotDePasse,
			})
			.then((response) => {
				console.log(response)
        window.location.href = '/homepage';
			})
        .catch((error) => {
      if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = Object.values(error.response.data.errors).flat();
        const errorMessage = validationErrors.join(' ');
        setErrorMessage(errorMessage);
      } else {
        console.log(error);
      }
    });
	}

  
  const handleButtonClick = () => {
    if (motDePasse !== confirmationMotDePasse) {
      setIsPasswordMatch(false);
      return; 
    }
    setIsPasswordMatch(true); 
    sendPost(); 
  };
  
  return (

    <div>
      <div className="images-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {transitions((style, index) => (
          <animated.img
            key={images[index].id}
            src={images[index].src}
            alt={`Image ${index + 1}`}
            className="animated-image"
            style={{ ...style, width: images[index].width, height: images[index].height }}
          />
        ))}
      </div>
      <h1 className='title3'> Partagez vos trajets et économisez de l'argent en covoiturant avec d'autres voyageurs </h1>
    <div className='cover1'>
      <h1>Création de mon compte :</h1>
      <div className='input-container'>
        <input type="text" placeholder="Nom" value={nom} onChange={(e) => setNom(e.target.value)} />
        <input type="text" placeholder="Prénom" value={prenom} onChange={(e) => setPrenom(e.target.value)} />
      </div>
      <div className='input-container'>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="number" placeholder="Numéro de téléphone" value={numeroTel} onChange={(e) => setNumeroTel(e.target.value)} />
      </div>
      <div className='input-container'>
        <input type="password" placeholder="Mot de passe" value={motDePasse} onChange={(e) => setMotDePasse(e.target.value)} />
        <input type="password" placeholder="Confirmer le mot de passe" value={confirmationMotDePasse} onChange={(e) => setConfirmationMotDePasse(e.target.value)} />
      </div>
      {!isPasswordMatch && (
  <p className='erreur'>Les mots de passe ne correspondent pas</p>
        )}
         {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className='login-btn1'onClick={handleButtonClick}>Créer mon compte</div>
    </div>
    </div>
  );
};

export default Xservices;