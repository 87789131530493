import React, { useState, useEffect} from 'react';
import vehicule from '../private/vehicule.png';
import './vehicule.css'


const Vehicule = () => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);

    return (
        <div className="container">
        <p className="nocar">Aucun véhicule ajouté</p>
        <img
          src={vehicule}
          alt="Description de l'image"
          className="centered-image"
        />
      </div>
    );
};

export default Vehicule;