import React, {useEffect ,useState} from 'react';

import image1 from './profil.png';
import image2 from './background.png';
import params from './parametres.png';
import saveIcon from './save.png';
import './profil.css';
import Footer from '../../components/public/Footer';
import axios from 'axios';

const Profil = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [text, setText] = useState('Ma bio');
  const [profilImage, setProfilImage] = useState(image1);
  const [backgroundImageFile, setBackgroundImageFile] = useState(null);
  const [profilImageFile, setProfilImageFile] = useState(null);
  
  
const [user, setUser] = useState(null);
  

const [newName, setNewName] = useState('');
const [newFirstName, setNewFirstName] = useState('');
const [newPhoneNumber, setNewPhoneNumber] = useState('');
const [currentPassword, setCurrentPassword] = useState('');
const [newPassword, setNewPassword] = useState('');
const [passwordError, setPasswordError] = useState(false);



  useEffect(() => {
    // Fonction pour récupérer l'utilisateur connecté
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token'); 
        const config = {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        };
        const response = await axios.get('https://test-api.bookair.space/api/getauthentificateduser', config);
        setUser(response.data.user);
        if (response.data.user && response.data.user.bio) {
          setText(response.data.user.bio);
        } else {
          setText('Ma bio');
        }
      } catch (error) {
        console.error('Failed to fetch authenticated user:', error);
      }
    };
    
    fetchUser();
  }, []);

 


  const handleClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSave = async () => {
    setIsEditing(false);
    console.log('Texte enregistré:', text);
    
    try {
      const token = localStorage.getItem('token'); 
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateBio',
        { bio: text },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      
      console.log('Bio enregistrée dans la base de données:', response.data);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la bio:', error);
    }
  };
  

  const handleBackgroundImageUpload = (event) => {
    const file = event.target.files[0];
    setBackgroundImageFile(file);
    const reader = new FileReader();

    reader.onload = (e) => {
      setBackgroundImage(e.target.result);
      saveImagesToDatabase();
    };

    reader.readAsDataURL(file);
  };

  const handleProfilImageUpload = (event) => {
    const file = event.target.files[0];
    setProfilImageFile(file);
    const reader = new FileReader();

    reader.onload = (e) => {
      setProfilImage(e.target.result);
      saveImagesToDatabase();
    };

    reader.readAsDataURL(file);
  };

  const saveImagesToDatabase = async () => {
    try {
      const formData = new FormData();
      formData.append('backgroundImage', backgroundImageFile);
      formData.append('profilImage', profilImageFile);
  
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios.post('https://test-api.bookair.space/api/save-images', formData, config);
      console.log('Réponse du serveur:', response.data);
      console.log('Images enregistrées avec succès.');
    } catch (error) {
      console.error('Failed to save images:', error);
    }
  };
  
  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };
  
  const handleFirstNameChange = (e) => {
    setNewFirstName(e.target.value);
  };
  
  const handlePhoneNumberChange = (e) => {
    setNewPhoneNumber(e.target.value);
  };
  
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordError(false); 
  };
  

  const handleUpdateUser = async () => {
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo',
        {
          name: newName,
          firstName: newFirstName,
          phoneNumber: newPhoneNumber,
         
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
   
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };
  
  
  const handleUpdateUser1 = async () => {
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo1',
        {
          
          firstName: newFirstName,
          
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
     
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };

  const handleUpdateUser2 = async () => {
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo2',
        {
          
          phoneNumber: newPhoneNumber,
          
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
   
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };

  const handleUpdateUser3 = async () => {
    if (currentPassword === '') {
      setPasswordError(true);
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://test-api.bookair.space/api/updateUserInfo3',
        {
          password: newPassword,
          
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Informations personnelles mises à jour:', response.data);
      setNewName('');
      setNewFirstName('');
      setNewPhoneNumber('');
   
    } catch (error) {
      console.log(newName);
      console.error('Erreur lors de la mise à jour des informations personnelles:', error);
    }
  };

  return (
    <div className='profiluser'>
      <input
        type='file'
        accept='image/*'
        onChange={handleBackgroundImageUpload}
        style={{ display: 'none' }}
        id='background-upload'
      />
          <label htmlFor='background-upload'>
            <img
              src={backgroundImage ? backgroundImage : image2}
              alt='background'
              className='background-image'
          />
          </label>
      <input
        type='file'
        accept='image/*'
        onChange={handleProfilImageUpload}
        style={{ display: 'none' }}
        id='profil-image-upload'
      />
      <label htmlFor='profil-image-upload'>
        <img
          src={profilImage}
          alt='profil'
          className='profil-image'
        />
        
      </label>

      {user && (
  <h1 className='idf'>
    {user.name} {user.firstName}
  </h1>
)}
      <div className='rectangle'>
        <img  
          src={params}
          alt='params'
          className='params-image'
          onClick={handleClick}
        />
       
        {isEditing ? (
          <div className='textarea-container'>
           
            <textarea
              value={text} 
              onChange={handleChange} 
              className='text-input'
            />
            <button onClick={handleSave} className='save-button'>
              <img src={saveIcon} alt='save-icon' className='save-icon' />
            </button>

          </div>
        ) : (
          <div className='text-content'>{text}</div>
        )}
  
  {isEditing && (
  <div>
    <div className='bottom-text'>Modifier les informations personnelles :</div>
    <div className='modification'>
      <div className='text1'
      >
        Nom
        <input className='side-textarea' 
        defaultValue={user.name}
        onChange={handleNameChange}
        />
      </div>
      <div className='text2'>
        Prénom
        <input className='side-textarea' 
        defaultValue={user.firstName}
        onChange={handleFirstNameChange}
        />
      </div>
      <div className='text3'>
        Numéro de téléphone
        <input className='side-textarea' 
        defaultValue={user.phoneNumber}
        onChange={handlePhoneNumberChange}
        
        />
      </div>
      <div className='text4'>
        Ancien mot de passe
        <input className='side-textarea' 
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </div>
      <div className='text5'>
        Nouveau mot de passe
        <input className='side-textarea' value={newPassword} type="password" onChange={handlePasswordChange}/>
      </div>
      {passwordError && <p className='error-message'>Veuillez entrer un mot de passe.</p>}

    </div>
    <button className='save-modification' onClick={() => {
  handleUpdateUser();
  handleUpdateUser1();
  handleUpdateUser2();
  handleUpdateUser3();
}}>
  Enregistrer
</button>
  </div>
)}
      </div> 
      <Footer />
    </div>
    
  );
};

export default Profil;
