import React, { useEffect, useState } from 'react';
import fleche from './fleche.png';
import itineraire from './itineraire.png';
import prix from './prix.png';
import details from './details.png'
import service from './detail.png'
import add from './addescal.png'

import './edittrip.css';

const EditTrip = () => {

  const [depart, setDepart] = useState('');
	const [arrivee, setArrivee] = useState('');
  const [id, setId] = useState(''); 
  const [date, setDate] = useState();
  const [heure, setHeure] = useState();
  const [mode, setMode] = useState('');   

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    const date = params.get('date');
    const heure = params.get('heure');
    const depart = params.get('depart');
    const arrivee = params.get('arrivee');
    const mode = params.get('mode');
  

    setId(id);
    setDate(date);
    setHeure(heure);
    setDepart(depart);
    setArrivee(arrivee);
    setMode(mode);

    console.log("the id", id);
    console.log("the date", date);
    console.log("the heure", heure);
    console.log("the departure", depart);
    console.log("the arrival", arrivee);
    console.log("the mode", mode);

  }, []);

  

  const handleGoBack = () => {
    window.history.back();
  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      window.location.href = '/login';
    }
  }, []);


  const handleEditClick1 = () => {
		
    const editTripLink = `/edit1?id=${id}&date=${date}&heure=${heure}`;
		window.location.href = editTripLink;
    };

    const handleEditClick2 = () => {
		
      const editTripLink = `/edit2?id=${id}&depart=${encodeURIComponent(depart)}&arrivee=${encodeURIComponent(arrivee)}`;
      window.location.href = editTripLink;
      };

      const handleEditClick3 = () => {
		
        const editTripLink = `/edit6?id=${id}&mode=${mode}`;
        window.location.href = editTripLink;
        };


      const handleEditClick4 = () => {
		
        const editTripLink = `/edit4?id=${id}&depart=${encodeURIComponent(depart)}&arrivee=${encodeURIComponent(arrivee)}`;
        window.location.href = editTripLink;
        };


  return (
    <div>
        <img src={fleche} alt="Retour" className="comeback1" onClick={handleGoBack} />
      <label htmlFor="arrivee" className="editrip-label">
          Modifier mon annonce
          </label>
      <div onClick={handleEditClick1} className="edit-the-trip">
        <img src={details} alt="Details" className="edit-icon1" />
        <span >Détails du trajet</span>
      </div>
      <hr className="edittrip-horizontal-line" />

      <div onClick={handleEditClick2} className="edit">
        <img src={itineraire} alt="Itineraire" className="edit-icon2" />
        <span >Itinéraire</span>
      </div>
      <hr className="edittrip-horizontal-line" />

      <div onClick={handleEditClick3} className="edit">
        <img src={prix} alt="Prix" className="edit-icon3" />
        <span >Prix</span>
      </div>
      <hr className="edittrip-horizontal-line" />

      <div className="edit" onClick={handleEditClick4}>
       
        <img src={service} alt="Places" className="edit-icon4" />
        <span >Places et service</span>
      </div>
      <hr className="edittrip-horizontal-line" />

    </div>
  );
};

export default EditTrip;
