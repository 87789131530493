import React, { useState, useEffect } from 'react';
import uploadIcon from './identite.png';
import fleche from './fleche.png'
import './drivinglicence.css';

const Drivinglicence = () => {
  const [uploadedImage, setUploadedImage] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Si le token n'existe pas, rediriger vers la page de connexion
      window.location.href = '/login';
      return;
    }

    const tokenExpirationDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    const currentTime = new Date();

    if (currentTime >= tokenExpirationDate) {
      // Si le token a expiré, rediriger vers la page de connexion
      window.location.href = '/login';
    }
  }, []);
  
  useEffect(() => {
    const storedImage = localStorage.getItem('uploadedImage');
    if (storedImage) {
      setUploadedImage(storedImage);
    }
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
        localStorage.setItem('uploadedImage', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClear = () => {
    setUploadedImage(null);
    localStorage.removeItem('uploadedImage');
  };

  const handleImageConfirm = () => {
    if (uploadedImage) {
      localStorage.setItem('uploadedImage', uploadedImage);
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };


  return (
    <div>
       <img src={fleche} alt="Retour" className="comeback6" onClick={handleGoBack} />
      <p className="drivinglicence-text">Recto du permis de conduire</p>
      {uploadedImage ? (
        <img
          src={uploadedImage}
          alt="Driving Licence"
          className={`icone-drivinglicence${uploadedImage ? ' uploaded' : ''}`}
          style={{
            width: '400px',
            height: '400px',
            border: '2px solid #130C4D',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}

        />
      ) : (
        <div className="upload-icon-container">
          <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
          <label htmlFor="image-upload" className="upload-label">
            Charger la photo de votre permis de conduire
          </label>
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
      )}

      {uploadedImage && (
        <div className="buttons-container">
          <button className="restart" onClick={handleImageClear}>
            Recommencer
          </button>
          <button className="confirm" onClick={handleImageConfirm}>
            Confirmer
          </button>
        </div>
      )}
    </div>
  );
};

export default Drivinglicence;
