import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './logo.png';
import deconnexion from './deconnexion.png';
import plusIcon from './plus.png';
import profil from './profil.png';
import accueil from './loupe.png';
import './header.css';

const Header = () => {
  const location = useLocation();
  const [isFromAccueilToRecherche, setIsFromAccueilToRecherche] = useState(false);

  useEffect(() => {
    if (location.state && location.state.from === '/accueil') {
      setIsFromAccueilToRecherche(true);
    } else {
      setIsFromAccueilToRecherche(false);
    }
  }, [location.state]);

  const isAccueil = location.pathname === '/homepage';
  const isProfil = location.pathname === '/profil';
  const isAjouttrajet = location.pathname === '/trajet';
  const isMesreservations = location.pathname === '/mesreservations';
  const isRecherchefromaccueil = location.pathname === '/search2';
  const isModification = location.pathname === '/modificationtrajets';
  const isdetailstrajet = location.pathname === '/tripdetails2';
  const isEtape1 = location.pathname === '/step1';
  const isEtape2 = location.pathname ==='/step2';
  const isEtape3 = location.pathname ==='/step3';
  const isEtape4 = location.pathname ==='/step4';
  const isEtape5 = location.pathname ==='/step5';
  const isEtape6 = location.pathname ==='/step6';
  const isEtape7 = location.pathname ==='/step7';
  const isPaiment = location.pathname ==='/paiement';
  const isCartebancaire = location.pathname === '/cartebancaire';
  const isListTrips = location.pathname === '/tripslist';
  const isTripDetails = location.pathname === '/tripdetails3';
  const isEditrip = location.pathname === '/editrip';
  const isEdit1 = location.pathname === '/edit1';
  const isEdit2 = location.pathname === '/edit2';
  const isEdit3 = location.pathname === '/edit3';
  const isEdit4 = location.pathname === '/edit4';
  const isEdit5 = location.pathname === '/edit5';
  const isEdit6 = location.pathname === '/edit6';
  const isVehicule = location.pathname === '/vehicule';
  const isMessages = location.pathname === '/messages';
  const isAddVehicule =  location.pathname === '/addvehicule';
  const isDrivingLicence = location.pathname === '/drivinglicence';
  const isIdcard = location.pathname === '/idcard';
  const isColisTrajet = location.pathname === '/colistrajet';
  const isColis = location.pathname === '/colis';
  const isColis1 = location.pathname === '/colis1';
  const isEditcolis = location.pathname === '/editpackage';
  const isRechercheColis = location.pathname === '/searchpackage'
  const isHomeSearch = location.pathname === '/homesearchpackage'
  const isResultatColis = location.pathname === '/result2'
  const isAnnonce = location.pathname === '/annonce'

 
  function handleDropdownToggle() {
    var dropdownContent = document.getElementById("myDropdown");
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  }
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/home'; 
  };

  const uploadedImage1 = localStorage.getItem('uploadedImage1');

  return (
    <div className="Header">
      <header>
        <nav>
          <ul>
            <li className="logo">
              <Link to={isAccueil ? "/homepage" : "/home"}>
                <img src={logo} alt="Logo" style={{ marginRight: "100px" }} />
              </Link>
            </li>
            {isAccueil && (
              <>
                <li className='mesreservations'onClick={() => (window.location.href = '/paiement')} > Mes réservations </li>
                <li className='mestrajets' onClick={() => (window.location.href = '/tripslist')}> Mes trajets </li>
                <li>
                  <button className='Ajout' onClick={() => window.location.href = '/colistrajet'}>Publier un trajet</button>
                </li>
                <li>
                  
                <div class="dropdown">
                  <img
                   src={profil}
                   className="toprofil"
                   onClick={handleDropdownToggle}
                   style={{ width: "50px", height: "50px", marginRight: "50px", marginTop: "20px" }}
                   alt="Icône Profil"
                   />    

              <div class="dropdown-content" id="myDropdown">
              <a href="/profil">Profil</a>
              <a href="/messages">Message</a>
              <a href="/annonce">Annonce</a>
              <a href="/login">Déconnexion</a>
             </div>
             </div>

                </li>
                <li>
                  <img src={deconnexion} onClick={handleLogout} alt="Icône Déconnexion" style={{ width: "30px", height: "30px", marginRight: "50px", marginTop: "25px" }} />
                </li>
              </>
            )}
            {(isListTrips ||isProfil || isAjouttrajet || isFromAccueilToRecherche || isMesreservations  || isRecherchefromaccueil || isModification
             || isdetailstrajet || isEtape1 || isEtape2 || isEtape3 || isEtape4 || isEtape5 || isEtape6 || isEtape7 || isPaiment || isCartebancaire ||
              isTripDetails || isIdcard|| isEditrip || isVehicule || isMessages || isAddVehicule || isDrivingLicence || isEdit1 || isEdit2 || 
              isEdit3 || isEdit4 || isEdit5 || isEdit6 || isColisTrajet || isColis || isEditcolis || isColis1 || isRechercheColis || isResultatColis || isAnnonce ) && (
              <>
                <li className="searchtrajet">
                  <Link to="/homepage">
                    <span className='rech' style={{ display: "flex", alignItems: "center", marginRight: "300px", marginTop: "5px", maxWidth: "600px" }}>
                      <img src={accueil} alt="Icone" className="icone" style={{ width: "35px", height: "35px", marginRight: "10px", marginTop: "5px" }} />
                      Rechercher un trajet
                    </span>
                  </Link>
                </li>
                <li className='mesreservations' onClick={() => (window.location.href = '/paiement')}> Mes réservations </li>
                <li className='mestrajets' onClick={() => (window.location.href = '/tripslist')}> Mes trajets </li>
                <li>
                  <button className='Ajout' onClick={() => window.location.href = '/colistrajet'}>Publier un trajet</button>
                </li>
                <li>
                <div class="dropdown">
                  <img
                   src={profil}
                   className="toprofil"
                   onClick={handleDropdownToggle}
                   style={{ width: "50px", height: "50px", marginRight: "50px", marginTop: "20px" }}
                   alt="Icône Profil"
                   />    

              <div class="dropdown-content" id="myDropdown">
              <a href="/profil">Profil</a>
              <a href="#">Message</a>
              <a href="/annonce">Annonce</a>
              <a href="/login">Déconnexion</a>
             </div>
             </div>
                </li>
                <li>
                  <img src={deconnexion} onClick={handleLogout} alt="Icône Déconnexion" style={{ width: "30px", height: "30px", marginRight: "50px", marginTop: "25px" }} />
                </li>
              </>
            )}
            {!isListTrips && !isAccueil && !isProfil && !isAjouttrajet && !isFromAccueilToRecherche && !isMesreservations  && !isRecherchefromaccueil 
            && !isModification && !isdetailstrajet && !isEtape1 && !isEtape2 && !isEtape3 && !isEtape4 && !isEtape5 && !isEtape6 && !isEtape7 && !isPaiment &&
            !isCartebancaire &&  !isTripDetails && !isEditrip && !isVehicule && !isMessages && !isAddVehicule && !isDrivingLicence && !isEdit1 && !isEdit2 && 
            !isEdit3 && !isEdit4 && !isIdcard && !isEdit5 && !isEdit6 && !isColisTrajet && !isColis  && !isEditcolis && !isColis1 && !isRechercheColis  && 
            !isResultatColis && !isAnnonce &&(
              <>
                <li className="mobile-icons">
                  <img src="icone1.png" alt="Icone 1" />
                  <img src="icone2.png" alt="Icone 2" />
                </li>
                <li className="pubtrajet">
                  <Link to="/login">
                    <span>
                      <img src={plusIcon} alt="Icone" className="icone" />
                      Publier un trajet?
                    </span>
                  </Link>
                </li>
                <li>
                  <button onClick={() => (window.location.href = '/login')}>
                    Se connecter
                  </button>
                </li>
                <li>
                  <button
                    className="blue"
                    onClick={() => (window.location.href = '/register')}
                  >
                    Créer un compte
                  </button>
                </li>
              </>
            )}
            
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;
